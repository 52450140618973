import Fetch from "lib/fetch";

export default class AuthService extends Fetch {
  // Request social log-in
  public async loginSocial(
    credentials: ReqLoginSocial,
  ): Promise<ResUserSocial> {
    try {
      const res = await this.post("/social-login", credentials);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request email log-in
  public async loginEmail(credentials: ReqLoginEmail): Promise<ResUser> {
    try {
      const res = await this.post("/login", credentials);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request token refresh
  public async refreshToken(token: string): Promise<ResUser> {
    try {
      const res = await this.post("/refresh-token", {
        token,
        version: "3.0.0",
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request email sign-up
  public async signUp(credentials: ReqSignUp) {
    try {
      const res = await this.post("/users", credentials);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request email sign-up with employee code
  public async signUpEmployeeCode(credentials: ReqSignUpEmployeeCode) {
    try {
      const res = await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/users-with-code`,
        credentials,
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request email sign-up with lawyer code
  public async signUpLawyerCode(credentials: ReqSignUpLawyerOfficeEmail) {
    try {
      const res = await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/users-with-code`,
        credentials,
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request reset password (forgot password)
  public async resetPassword(email: string) {
    try {
      const res = await this.post("/reset-password", { email });
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request password change
  public async changePassword(data: ReqChangePassword) {
    try {
      const res = await this.put("/users", data);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request send mobile verification code
  public async sendMobileVerificationCode(data: ReqSendMobileVerfiicationCode) {
    try {
      const res = await this.post("/start-verification", data);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request check mobile verification code
  public async checkMobileVerificationCode(
    data: ReqCheckMobileVerfiicationCode,
  ) {
    try {
      const res = await this.post("/check-verification", data);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Update user fields
  public async updateUser(userId: number, data: Partial<ReqUpdateUser>) {
    try {
      const res = await this.put(`/users/${userId}`, data);
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request send email address verification email
  public async sendVerificationEmail(data: ReqSendValidationEmail) {
    try {
      const res = await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/users/send-verification-email`,
        data,
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  // Request email verification with the prodived token
  public async verifyEmailWithToken(token: string) {
    try {
      const res = await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/users/verify-email/${token}`,
        {},
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @throws {CommunityProfileNotFound} Community profile not created.
   */
  public async getProfile() {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/users/profile`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async updateProfile(data: {
    profileImage?: string;
    firstName: string;
    lastName: string;
  }) {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/users/profile`,
        data,
      );
    } catch (e) {
      throw e;
    }
  }

  public async uploadProfileImage(file: File): Promise<ResProfile> {
    const fd = new FormData();
    fd.append("profileImage", file);
    try {
      return await this.upload(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/upload-profile-image`, // TODO: request api update, /community -> /users
        fd,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getTimeline(): Promise<ResCaseTimeline> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/timeline`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getOrganizationList(): Promise<Array<ResListOrganization>> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/organizations`,
      );
    } catch (e) {
      throw e;
    }
  }
}

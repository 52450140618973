import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import FilingService from "domain/filing/filingService";
import { useAuth } from "domain/auth/authContext";

interface FilingStore {}

type FilingAction = any;

const FilingContext = createContext<
  [FilingStore, React.Dispatch<FilingAction>] | null
>(null);

function FilingProvider(props: any) {
  const [state, dispatch] = useReducer(selfApplicationReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  const { children, ...rest } = props;

  return (
    <FilingContext.Provider value={value} {...rest}>
      {children}
    </FilingContext.Provider>
  );
}
function useFiling() {
  const context = useContext(FilingContext);

  const {
    state: { dataUser },
    doRefreshToken,
  } = useAuth();

  const service = useMemo(
    () => new FilingService(dataUser ? { token: dataUser.token } : undefined),
    [dataUser],
  );

  if (!context) {
    throw new Error(`useFiling must be used within a FilingProvider`);
  }

  const [state, dispatch] = context;

  const doActivateUser = useCallback(async () => {
    try {
      await service.activateUser();
      return await doRefreshToken();
    } catch (e) {
      throw e;
    }
  }, [doRefreshToken, service]);

  const doVerifyEmailWithToken = useCallback(
    async (token: string) => {
      return await service.verifyEmailWithToken(token);
    },
    [service],
  );

  const doCreateApplication = useCallback(async (): Promise<{ id: string }> => {
    try {
      return await service.createApplication();
    } catch (e) {
      throw e;
    }
  }, [service]);

  const doDeleteApplication = useCallback(
    async (id: string) => {
      try {
        return await service.deleteApplication(id);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doGetApplication = useCallback(
    async (id: string) => {
      try {
        return await service.getApplication(id);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doPostAnswer = useCallback(
    async (
      id: string,
      key: string,
      value: any,
      { reset }: { reset?: boolean } = {},
    ) => {
      try {
        if (reset) {
          await service.deleteAnswer(id, key);
        }
        return await service.postAnswer(id, key, value);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doUploadFiles = useCallback(
    async (id: string, key: string, files: File[]) => {
      try {
        return await Promise.all(
          files.map((x) => service.uploadFile(id, key, x)),
        );
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doDeleteFile = useCallback(
    async (applicationId: string, fileId: number) => {
      try {
        await service.deleteFile(applicationId, fileId);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doCreateCheckoutSession = useCallback(
    async (
      applicationId: string,
      params: { successUrl: string; cancelUrl: string },
    ) => {
      try {
        return await service.createCheckoutSession(applicationId, params);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doRequestReview = useCallback(
    async (applicationId: string) => {
      try {
        return await service.requestReview(applicationId);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doUpdateApplicationName = useCallback(
    async (applicationId: string, name: string) => {
      try {
        return await service.updateApplicationName(applicationId, name);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  return {
    state,
    dispatch,
    service,
    doActivateUser,
    doVerifyEmailWithToken,
    doCreateApplication,
    doDeleteApplication,
    doGetApplication,
    doPostAnswer,
    doUploadFiles,
    doDeleteFile,
    doCreateCheckoutSession,
    doRequestReview,
    doUpdateApplicationName,
  };

  // helper functions go beneath return statement
}

const initialState: FilingStore = {
  dataPaymentIntent: null,
  isModalPaymentVisible: false,
  imgModalSrc: null,
};

function selfApplicationReducer(
  state: FilingStore,
  action: FilingAction,
): FilingStore {
  switch (action.type) {
    case "SET_SA_PAYMENT_INTENT": {
      return {
        ...state,
        dataPaymentIntent: action.val,
      };
    }

    case "SET_SA_PAYMENT_MODAL_VISIBLITY": {
      return {
        ...state,
        isModalPaymentVisible: action.val,
      };
    }

    case "SET_SA_IMG_MODAL_SRC": {
      return {
        ...state,
        imgModalSrc: action.val,
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { FilingProvider, useFiling };

import Fetch from "../../lib/fetch";

export default class ConsultationService extends Fetch {
  // Request social log-in
  public async getConsultationDetail(
    consultationId: string,
  ): Promise<ResConsultationDetail> {
    try {
      return await this.get(`/consultations/${consultationId}`);
    } catch (e) {
      throw e;
    }
  }

  public async getConsultationChatInfo(
    consultationId: string,
  ): Promise<ResConsultationChatInfo> {
    try {
      return await this.get(`/consultations/${consultationId}/chat-info`);
    } catch (e) {
      throw e;
    }
  }

  public async postCancelConsultation(
    consultationId: string,
  ): Promise<ResConsultationDetail> {
    try {
      return await this.post(`/consultations/${consultationId}/cancel`, {});
    } catch (e) {
      throw e;
    }
  }

  public async postCompleteConsultation(
    consultationId: string,
  ): Promise<ResConsultationDetail> {
    try {
      return await this.post(`/consultations/${consultationId}/complete`, {});
    } catch (e) {
      throw e;
    }
  }

  public async postCreateReview(payload: ReqReview): Promise<void> {
    try {
      await this.post(`/reviews`, payload);
      return;
    } catch (e) {
      throw e;
    }
  }

  public async getListConsultationUpcoming(): Promise<
    ResConsultationRequest[]
  > {
    try {
      return await this.get("/consultations", {
        isComplete: false,
      });
    } catch (e) {
      throw e;
    }
  }

  public async getListConsultationHistory(): Promise<ResConsultationRequest[]> {
    try {
      return await this.get("/consultations", {
        isComplete: true,
      });
    } catch (e) {
      throw e;
    }
  }

  public async getConsultationPaymentDetail(
    consultationId: string,
  ): Promise<ResConsultationPaymentDetail> {
    try {
      return await this.get(
        `/consultations/${consultationId}/pi-client-secret`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async createPaymentIntent(consultationId: string) {
    try {
      return await this.post(
        `/consultations/${consultationId}/payment-intent`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }

  public async postCompleteConsultationPayment(consultationId: string) {
    try {
      return await this.post(
        `/consultations/${consultationId}/complete-payment`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }
}

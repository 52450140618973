import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useEffect,
  useCallback,
} from "react";
import dayjs from "dayjs";
import { useAuth } from "domain/auth/authContext";

interface PushNotificationStore {
  connection: WebSocket | null;
  dataPushNotification: ResPushNoticationDetail | null;
}

type PushNotificationAction =
  | { type: "SET_PUSH_NOTIFICATION"; val: any }
  | { type: "SET_CONNECTION"; val: WebSocket | null };

const PushNotificationContext = createContext<
  [PushNotificationStore, React.Dispatch<PushNotificationAction>] | null
>(null);

function PushNotificationProvider(props: any) {
  const [state, dispatch] = useReducer(pushNotificationReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  const { state: authState } = useAuth();

  const disconnect = useCallback(() => {
    if (state.connection) {
      console.log(dayjs().format("hh:mm:ss"), "ws disconnecting");
      try {
        state.connection.close();
        dispatch({
          type: "SET_CONNECTION",
          val: null,
        });
      } catch (e) {
        throw e;
      }
    }
  }, [state.connection]);

  const onOpen = useCallback(() => {
    console.log(dayjs().format("hh:mm:ss"), "ws connection open");
  }, []);

  const onMessage = useCallback(
    (evt: MessageEvent) => {
      try {
        const parsedData: {
          consultationId: string;
          eventType: string;
        } = JSON.parse(evt?.data);

        console.log("ws message received", parsedData);

        dispatch({
          type: "SET_PUSH_NOTIFICATION",
          val: parsedData,
        });
      } catch (e) {
        console.warn("message parse failed.");
      }
    },
    [dispatch],
  );

  const onClose = useCallback(() => {
    console.log(dayjs().format("hh:mm:ss"), "ws connection closed");
    disconnect();
  }, [disconnect]);

  const connect = useCallback(
    (userId: number) => {
      try {
        console.log(dayjs().format("hh:mm:ss"), "connecting");
        const ws = new WebSocket(
          `${process.env.NEXT_PUBLIC_WEBSOCKET_BASE_URL}/${encodeURIComponent(
            userId,
          )}`,
        );
        dispatch({
          type: "SET_CONNECTION",
          val: ws,
        });
      } catch (e) {
        // ACTUALLY WEBSOCKET INSTANTIATION EXCEPTIONS ARE NOT CAUGHT BY DESIGN
        // https://stackoverflow.com/questions/31002592/javascript-doesnt-catch-error-in-websocket-instantiation/31003057
        console.warn("websocket connection establishment failed."); // WE WILL NEVER SEE THIS
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (authState.dataUser && !state.connection) {
      // if user is logged in then try/retry ws connection
      disconnect();
      connect(authState.dataUser.id);
    }
  }, [authState.dataUser, disconnect, connect, state.connection]);

  useEffect(() => {
    if (state.connection) {
      state.connection.onopen = onOpen;
      state.connection.onmessage = onMessage;
      state.connection.onclose = onClose;
    }
  }, [state.connection, onOpen, onMessage, onClose]);

  return <PushNotificationContext.Provider value={value} {...props} />;
}

function usePushNotification() {
  const context = useContext(PushNotificationContext);

  if (!context) {
    throw new Error(
      `usePushNotification must be used within a PushNotificationProvider`,
    );
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

const initialState = {
  connection: null,
  dataPushNotification: null,
};

function pushNotificationReducer(
  state: PushNotificationStore,
  action: PushNotificationAction,
): PushNotificationStore {
  switch (action.type) {
    case "SET_PUSH_NOTIFICATION": {
      return {
        ...state,
        dataPushNotification: action.val,
      };
    }

    case "SET_CONNECTION": {
      return {
        ...state,
        connection: action.val,
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { PushNotificationProvider, usePushNotification };

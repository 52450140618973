import React from "react";
import styled from "styled-components";
import MuiButton from "@material-ui/core/Button";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { useLayout } from "lib/contexts/layoutContext";

const ContentWrapper = styled(MuiDialogContent)`
  &&,
  &&:first-child {
    min-width: 200px;
    padding: 31px 22px;
    font-size: 14px;
    line-height: 1.57;
    text-align: center;
    color: ${({ theme }) => theme.palette.common.black};
    white-space: pre-line;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  }
`;

const ButtonWrapper = styled(MuiDialogActions)`
  && {
    padding: 0;
  }
`;

const AlertButton = styled(MuiButton)`
  && {
    box-sizing: border-box;
    width: 100%;
    min-height: 58px;
    margin: 0;
    padding: 20px;
    border-radius: 0;
    border-left: 1px solid ${({ theme }) => theme.palette.grey[100]};

    :focus {
      background-color: ${({ theme }) => theme.palette.grey[50]};
    }
    :first-child {
      border: 0;
    }
    :not(:first-child) {
      margin-left: 0;
    }
  }
`;

const Alert: React.FC = () => {
  const {
    state: { isAlertOpen, dataAlert },
    doCloseAlert,
  } = useLayout();

  let disableBackdropClick;
  let disableEscapeKeyDown;

  if (dataAlert) {
    ({ disableBackdropClick = true, disableEscapeKeyDown = true } = dataAlert);
  }

  const renderFooter = () => {
    if (dataAlert?.noButtons) {
      return null;
    }
    const buttons: React.ReactNode[] = [];
    if (dataAlert?.onCancel) {
      buttons.push(
        <AlertButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          key="modal-cancel-button"
          color="primary"
          onClick={handleOnCancel}
          variant="text"
        >
          {dataAlert.cancelMessage || "No"}
        </AlertButton>,
      );
    }

    if (dataAlert?.onCustom) {
      buttons.push(
        <AlertButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          key="modal-custom-button"
          color="primary"
          onClick={handleOnCustom}
          variant="text"
        >
          {dataAlert?.customMessage}
        </AlertButton>,
      );
    }

    buttons.push(
      <AlertButton
        disableRipple
        disableFocusRipple
        disableTouchRipple
        key="modal-confirm-button"
        color={dataAlert?.isNegative ? "secondary" : "primary"}
        onClick={handleOnConfirm}
        variant="text"
      >
        {dataAlert?.confirmMessage
          ? dataAlert?.confirmMessage
          : dataAlert?.onCancel
          ? "Yes"
          : "OK"}
      </AlertButton>,
    );
    return buttons;
  };

  const handleOnCustom = () => {
    doCloseAlert();
    if (dataAlert?.onCustom) {
      dataAlert?.onCustom();
    }
  };

  const handleOnCancel = () => {
    doCloseAlert();
    if (dataAlert?.onCancel) {
      dataAlert?.onCancel();
    }
  };

  const handleOnConfirm = () => {
    doCloseAlert();
    if (dataAlert?.onConfirm) {
      dataAlert?.onConfirm();
    }
  };

  return (
    <MuiDialog
      open={isAlertOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" && disableBackdropClick) {
          return;
        } else if (reason === "escapeKeyDown" && disableEscapeKeyDown) {
          return;
        }
        handleOnCancel();
      }}
    >
      <ContentWrapper>{dataAlert?.content}</ContentWrapper>
      <ButtonWrapper>{renderFooter()}</ButtonWrapper>
    </MuiDialog>
  );
};

export default Alert;

import Fetch from "../../lib/fetch";

export default class CommunityService extends Fetch {
  /**
   * Request the list of all avaiable community categories
   */
  public async getListCategory(): Promise<ResCategoryCommunity[]> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/categories`,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * (Public) Request the paged list of all posts within the supplied parameters
   *
   * @param {ReqListPostCommunity} params
   * @returns {Promise<ResPagedData<ResCommunityPostItem>>}
   * @memberof CommunityService
   */
  public async getPublicListPost(
    params: ReqListPostCommunity,
  ): Promise<ResPagedData<ResCommunityPostItem>> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/public-posts`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * (Private) Request the paged list of all posts within the supplied parameters
   *
   * @param {ReqListPostCommunity} params
   * @returns {Promise<ResPagedData<ResCommunityPostItem>>}
   * @memberof CommunityService
   */
  public async getPrivateListPost(
    params: ReqListPostCommunity,
  ): Promise<ResPagedData<ResCommunityPostItem>> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * (Private/Public) Request the paged list of all posts within the supplied parameters
   * Resorts to public posts if not logged in
   *
   * @param {ReqListPostCommunity} params
   * @returns {Promise<ResPagedData<ResCommunityPostItem>>}
   * @memberof CommunityService
   */
  public async getListPost(
    params: ReqListPostCommunity,
  ): Promise<ResPagedData<ResCommunityPostItem>> {
    try {
      if (this.apiConfig?.token) {
        return await this.getPrivateListPost(params);
      }
      return await this.getPublicListPost(params);
    } catch (e) {
      if (e.name === "Unauthorized") {
        return this.getPublicListPost(params);
      }
      throw e;
    }
  }

  /**
   * Request the list of community tags
   *
   * @param {ReqListTagCommunity} params
   * @returns {Promise<ResTagCommunity[]>}
   * @memberof CommunityService
   */
  public async getListTag(
    params: ReqListTagCommunity,
  ): Promise<ResTagCommunity[]> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/tags`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getPostPublic(
    slug: string,
  ): Promise<ResCommunityPostPost | ResCommunityPostNews> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/public-posts/${slug}`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getPostPrivate(
    slug: string,
  ): Promise<ResCommunityPostPost | ResCommunityPostNews> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts/${slug}`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getPost(
    slug: string,
  ): Promise<ResCommunityPostPost | ResCommunityPostNews> {
    try {
      if (this.apiConfig?.token) {
        return await this.getPostPrivate(slug);
      }
      return await this.getPostPublic(slug);
    } catch (e) {
      if (e.name === "Unauthorized") {
        return this.getPostPublic(slug);
      }
      throw e;
    }
  }

  public async createPost({
    categoryId,
    title,
    contents,
    isWithTimeline,
  }: ReqCreatePost): Promise<ResCommunityPostPost> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts`,
        {
          categoryId,
          title,
          contents,
          isWithTimeline,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  public async editPost(data: {
    postId: number;
    categoryId: number;
    title: string;
    contents: string;
  }): Promise<ResCommunityPostPost> {
    try {
      return await this.put(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts/${data.postId}`,
        data,
      );
    } catch (e) {
      throw e;
    }
  }

  public async updatePostLike(
    postId: number,
    like: boolean,
  ): Promise<ResCommunityPostPost> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts/${postId}/like`,
        {
          like,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  public async deletePost(postId: number) {
    try {
      return await this.delete(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts/${postId}`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async deleteComment(commentId: number): Promise<void> {
    try {
      return await this.delete(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/comments/${commentId}`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getListCommentPublic({
    postSlug,
    pageNumber,
    pageSize,
  }: {
    postSlug: string;
    pageNumber: number;
    pageSize: number;
  }): Promise<{
    firstPage: boolean;
    lastPage: boolean;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    items: ResCommunityComment[];
  }> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/public-posts/${postSlug}/comments`,
        { pageNumber, pageSize },
      );
    } catch (e) {
      throw e;
    }
  }

  public async getListComment({
    postSlug,
    pageNumber,
    pageSize,
  }: {
    postSlug: string;
    pageNumber: number;
    pageSize: number;
  }): Promise<{
    firstPage: boolean;
    lastPage: boolean;
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    items: ResCommunityComment[];
  }> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts/${postSlug}/comments`,
        { pageNumber, pageSize },
      );
    } catch (e) {
      throw e;
    }
  }

  public async createComment(
    postId: number,
    comment: string,
  ): Promise<ResCommunityComment> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts/${postId}/comments`,
        {
          comment,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  public async editComment({
    commentId,
    comment,
  }: {
    commentId: number;
    comment: string;
  }): Promise<ResCommunityComment> {
    try {
      return await this.put(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/comments/${commentId}`,
        {
          comment,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  public async reportPost({
    postId,
    title,
    contents,
  }: {
    postId: number;
    title: string;
    contents: string;
  }): Promise<ResCommunityPostPost> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/posts/${postId}/report`,
        {
          title,
          contents,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  public async reportComment({
    commentId,
    title,
    contents,
  }: {
    commentId: number;
    title: string;
    contents: string;
  }): Promise<void> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/comments/${commentId}/report`,
        {
          title,
          contents,
        },
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * @throws {CommunityProfileNotFound} Community profile not created.
   */
  public async getProfile() {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/users/profile`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async updateProfile(data: {
    profileImage?: string;
    firstName: string;
    lastName: string;
  }) {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/users/profile`,
        data,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getMyActivityPosts(
    page: number,
  ): Promise<ResCommunityListActivity> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/activities/posts?page_size=15&page_number=${page}`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getMyActivityLikes(page: number) {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/community/activities/likes?page_size=15&page_number=${page}`,
      );
    } catch (e) {
      throw e;
    }
  }
}

import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { nanoid } from "nanoid";
import { Toaster } from "react-hot-toast";
import Alert from "lib/components/Alert";
import Toasts from "lib/components/Toasts";
import { ToastType } from "lib/enums";
import ModalShared from "lib/components/ModalShared";

interface AlertInterface {
  content: React.ReactNode;
  confirmMessage?: string;
  cancelMessage?: string;
  customMessage?: string;
  isNegative?: boolean;
  isError?: boolean;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onCustom?: () => void;
  noButtons?: boolean;
}

interface ModalInterface {
  content: React.ReactNode;
  title?: string;
  fullScreen?: boolean;
  skippable?: boolean;
  titleFontSize?: string;
}

interface ToastInterface {
  id: string;
  duration?: number | null;
  content?: React.ReactNode;
  type?: ToastType;
  showClose?: boolean;
}

interface LayoutStore {
  isAlertOpen: boolean;
  isToastOpen: boolean;
  isModalOpen: boolean;
  dataAlert: AlertInterface | null;
  dataModal: ModalInterface | null;
  dataListToast: ToastInterface[];
}

type LayoutAction =
  | {
      type: "SET_IS_ALERT_OPEN";
      val: boolean;
      data: AlertInterface | null;
    }
  | {
      type: "SET_IS_MODAL_OPEN";
      val: boolean;
      data: ModalInterface | null;
    }
  | {
      type: "ENQUEUE_TOAST";
      data: ToastInterface;
    }
  | {
      type: "DEQUEUE_TOAST";
      val: string;
    };

const LayoutContext = createContext<
  [LayoutStore, React.Dispatch<LayoutAction>] | null
>(null);

const LayoutProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(layoutReducer, initialState);

  const value: [LayoutStore, React.Dispatch<LayoutAction>] = useMemo(
    () => [state, dispatch],
    [state],
  );

  return (
    <LayoutContext.Provider value={value}>
      <Alert />
      <Toasts />
      <ModalShared />
      {children}
      <Toaster />
    </LayoutContext.Provider>
  );
};

function useLayout() {
  const context = useContext(LayoutContext);

  if (!context) {
    throw new Error(`useLayout must be used within a LayoutProvider`);
  }

  const [state, dispatch] = context;

  const doOpenAlert = useCallback(
    (option: AlertInterface) => {
      dispatch({
        type: "SET_IS_ALERT_OPEN",
        val: true,
        data: option,
      });
    },
    [dispatch],
  );

  const doCloseAlert = useCallback(() => {
    dispatch({
      type: "SET_IS_ALERT_OPEN",
      val: false,
      data: null,
    });
  }, [dispatch]);

  const doOpenModal = useCallback(
    (option: ModalInterface) => {
      dispatch({
        type: "SET_IS_MODAL_OPEN",
        val: true,
        data: option,
      });
    },
    [dispatch],
  );

  const doCloseModal = useCallback(() => {
    dispatch({
      type: "SET_IS_MODAL_OPEN",
      val: false,
      data: null,
    });
  }, [dispatch]);

  const doOpenToast = useCallback(
    (option: Omit<ToastInterface, "id">) => {
      dispatch({
        type: "ENQUEUE_TOAST",
        data: { ...option, id: nanoid() },
      });
    },
    [dispatch],
  );

  const doCloseToast = useCallback(
    (toastId: string) => {
      dispatch({
        type: "DEQUEUE_TOAST",
        val: toastId,
      });
    },
    [dispatch],
  );

  return {
    state,
    dispatch,
    doOpenAlert,
    doCloseAlert,
    doOpenToast,
    doCloseToast,
    doOpenModal,
    doCloseModal,
  };

  // helper functions go beneath return statement
}

const initialState = {
  isAlertOpen: false,
  isModalOpen: false,
  isToastOpen: false,
  dataModal: null,
  dataAlert: null,
  dataListToast: [],
};

function layoutReducer(state: LayoutStore, action: LayoutAction) {
  switch (action.type) {
    case "SET_IS_ALERT_OPEN": {
      return {
        ...state,
        isAlertOpen: action.val,
        // Keep old data for natural transition.
        dataAlert: action.val ? action.data : state.dataAlert,
      };
    }

    case "SET_IS_MODAL_OPEN": {
      return {
        ...state,
        isModalOpen: action.val,
        dataModal: action.data,
      };
    }

    case "ENQUEUE_TOAST": {
      return {
        ...state,
        dataListToast: [
          ...(state.dataListToast.length < 5
            ? state.dataListToast
            : state.dataListToast.slice(1)),
          action.data,
        ],
      };
    }

    case "DEQUEUE_TOAST": {
      return {
        ...state,
        dataListToast: state.dataListToast.filter(
          ({ id }) => id !== action.val,
        ),
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { LayoutProvider, useLayout };

export enum DeviceError {
  NotFoundError = "NotFoundError",
  NotAllowedError = "NotAllowedError",
  NotReadableError = "NotReadableError",
}

export enum KeyboardKey {
  Delete = "Delete",
  Backspace = "Backspace",
  Comma = ",",
  Enter = "Enter",
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  Esc = "Esc", // for older browsers
  Escape = "Escape",
  Tab = "Tab",
}

export enum ConsultationRequestStatus {
  Requested = "requested",
  Rejected = "rejected",
  Expired = "expired",
  Confirmed = "confirmed",
  Completed = "completed",
  ConsultationOpen = "consultation_open",
  LawyerReady = "lawyer_ready",
  ConsultationStarted = "consultation_started",
  CanceledByClient = "canceled_by_client",
  CanceledByLawyer = "canceled_by_lawyer",
}

export enum ConsultationBookType {
  Live = "live",
  Booking = "booking",
}

export enum AsyncStatus {
  Initial = "initial",
  Requested = "requested",
  Success = "success",
  Error = "error",
}

/**
 * Keep all custom event labels here for consistency
 */
export enum CustomEventLabel {
  // WC_View_ tags are VIEW tags
  // GTM relays following tags along with the page_path and page_url properties
  ViewConsultationUpcoming = "WC_View_Consultation_Upcoming",
  ViewConsultationHistory = "WC_View_Consultation_History",
  ViewConsultationRequest = "WC_View_Consultation_Request",
  ViewConsultationStandby = "WC_View_Consultation_Standby",
  ViewConsultationVideoChat = "WC_View_Consultation_VideoChat",
  ViewConsultationComplete = "WC_View_Consultation_Complete",
  ViewUserSignUp = "WC_View_User_SignUp",
  ViewUserSignUpEmail = "WC_View_User_SignUpEmail",
  ViewUserLogin = "WC_View_User_Login",
  ViewUserForgotPassword = "WC_View_User_ForgotPassword",
  ViewUserChangePassword = "WC_View_User_ChangePassword",
  ViewDeviceUnsupported = "WC_View_Device_Unsupported",
  ViewDeviceUnsupportedRTC = "WC_View_Device_UnsupportedRTC",
  ViewCommunityList = "WC_View_Community_List",
  ViewCommunityListTag = "WC_View_Community_List_Tag",
  ViewCommunityListSearch = "WC_View_Community_List_Search",
  ViewCommunityPostDetail = "WC_View_Community_Post_Detail",
  ViewCommunityPostCreate = "WC_View_Community_Post_Create",
  // WC_EVT_ tags are EVENT tags
  // GTM assumes it may contain eventData property in the Data Layer
  TopNavLogOut = "WC_EVT_TopNav_LogOut",
  SignUpSelectSignUpMethodTermsOfService = "WC_EVT_SignUp_SelectSignUpMethod_TermsOfService",
  SignUpSelectSignUpMethodPrivatePolicy = "WC_EVT_SignUp_SelectSignUpMethod_PrivatePolicy",
  SignUpEmailSignUpCTA = "WC_EVT_SignUp_Email_SignUpCTA",
  SignUpEmailLogInCTA = "WC_EVT_SignUp_Email_LogInCTA",
  LogInSelectLogInMethodLogInCTA = "WC_EVT_LogIn_SelectLogInMethod_LogInCTA",
  LogInSelectLogInMethodForgotPassword = "WC_EVT_LogIn_SelectLogInMethod_ForgotPassword",
  InConsultationConsultationType = "WC_EVT_InConsultation_ConsultationType",
  InConsultationNavBarLeaveConsultation = "WC_EVT_InConsultation_NavBar_LeaveConsultation",
  InConsultationVideoAreaCameraToggle = "WC_EVT_InConsultation_VideoArea_CameraToggle",
  InConsultationVideoAreaAudioToggle = "WC_EVT_InConsultation_VideoArea_AudioToggle",
  InConsultationVideoAreaMore = "WC_EVT_InConsultation_VideoArea_More",
  MyAppointmentTabBarMenu = "WC_EVT_MyAppointment_TabBar_Menu",
  MyAppointmentUpcomingJoinConsultation = "WC_EVT_MyAppointment_Upcoming_JoinConsultation",
  MyAppointmentGoToLawyerSearchPage = "WC_EVT_MyAppointment_GoToLawyerSearchPage",
  RequestAConsultationSubmissionScreenConsultationType = "WC_EVT_RequestAConsultation_SubmissionScreen_ConsultationType",
  RequestAConsultationSubmissionScreenAddMethodCTA = "WC_EVT_RequestAConsultation_SubmissionScreen_AddMethodCTA",
  RequestAConsultationSubmissionScreenEditMethodCTA = "WC_EVT_RequestAConsultation_SubmissionScreen_EditMethodCTA",
  RequestAConsultationSubmissionScreenTermsOfService = "WC_EVT_RequestAConsultation_SubmissionScreen_TermsOfService",
  RequestAConsultationSubmissionScreenBookAppoinmentCTA = "WC_EVT_RequestAConsultation_SubmissionScreen_BookAppoinmentCTA",
  RequestAConsultationWaitingScreenWaitingForResponseCancelCTA = "WC_EVT_RequestAConsultation_WaitingScreen_WaitingForResponseCancelCTA",
  RequestAConsultationWaitingScreenRequestDevicePermission = "WC_EVT_RequestAConsultation_WaitingScreen_RequestDevicePermission",
  RequestAConsultationWaitingScreenJoinWithin2min = "WC_EVT_RequestAConsultation_WaitingScreen_JoinWithin2min",
  RequestAConsultationWaitingScreenRequestAgain = "WC_EVT_RequestAConsultation_WaitingScreen_RequestAgain",
  RequestAConsultationWaitingScreenFindAnotherLawyer = "WC_EVT_RequestAConsultation_WaitingScreen_FindAnotherLawyer",
  RequestAConsultationWaitingScreenResume = "WC_EVT_RequestAConsultation_WaitingScreen_Resume",
  ConsultationReviewConsultationEndedDone = "WC_EVT_ConsultationReview_ConsultationEnded_Done",
  FindALawyerFilterState = "WC_EVT_FindALawyer_Filter_State",
  FindALawyerFilterLanguage = "WC_EVT_FindALawyer_Filter_Language",
  FindALawyerFilterPracticeArea = "WC_EVT_FindALawyer_Filter_PracticeArea",
  FindALawyerFilterLiveOnly = "WC_EVT_FindALawyer_Filter_LiveOnly",
  FindALawyerProfileLiveCTA = "WC_EVT_FindALawyer_Profile_LiveCTA",
  FindALawyerProfileBookingCTA = "WC_EVT_FindALawyer_Profile_BookingCTA",
  FindALawyerLawyerListProfileArea = "WC_EVT_FindALawyer_LawyerList_ProfileArea",
  // WC_MT_ tags are MANUAL TRIGGER tags that are intercepted by GTM
  // GTM in turn invokes an appropriate tag for the context (must be implemented)
  SignUpLogInMethodType = "WC_MT_SignUpLogInMethodType",
  Identify = "identify", // identify users
  RouteChange = "routeChange",
}

/**
 * Object events sent to Segment analytics
 */
export enum ObjectEvent {
  ApplicationsEligibilityTestStarted = "[Applications] - [Eligibility test started]",
  ApplicationsEligibilityTestCompleted = "[Applications] - [Eligibility test completed]",
  ApplicationsApplicationCreated = "[Applications] - [Application created]",
  ApplicationsApplicationStatusChanged = "[Applications] - [Application status changed]",
  ApplicationsApplicationPaymentCompleted = "[Applications] - [Application payment completed]",
  UserSignUpCompleted = "[User] - [Sign-up completed]",
  UserEmailVerificationCompleted = "[User] - [Email verification completed]",
  CommunityPostCreated = "[Community] - [Post created]",
  CommunityCommentCreated = "[Community] - [Comment created]",
  CTLRegistered = "[Case Tracker Lite] - [Case registered]",
  RemittancePayerAccountCreated = "[Remittance] - [Payer account created]",
  RemittancePayerBankAccountConnected = "[Remittance] - [Payer bank account connected]",
  RemittanceBeneficiaryCreated = "[Remittance] - [Beneficiary created]",
  RemittanceTransferCreated = "[Remittance] - [Transfer created]",
  RemittanceTransferCanceled = "[Remittance] - [Transfer canceled]",
}

export enum UIEvent {
  TimelineAppDownload = "$[Timeline detail] - [Click app download]",
  CaseTrackerPhoneNumberSubmitted = "$[Case Tracker] - [Desktop phone number submitted]",
  CaseTrackerDesktopAppDownload = "$[Case Tracker] - [Desktop app download clicked]",
  CaseTrackerMobileAppDownload = "$[Case Tracker] - [Mobile app download clicked]",
  CaseTrackerMobileTopAppDownloadClicked = "$[Case Tracker] - [Mobile top app download clicked]",
  MockInterviewPhoneNumberSubmitted = "$[Mock Interview] - [Desktop phone number submitted]",
  MockInterviewDesktopAppDownload = "$[Mock Interview] - [Desktop app download clicked]",
  MockInterviewMobileAppDownload = "$[Mock Interview] - [Mobile app download clicked]",
  CommunityMobileBannerAppDownload = "$[Community] - [Mobile banner app download clicked]",
  CommunityBannerUSCISStatusExplorerClicked = "$[Community] - [Banner uscis status explorer clicked]",
  CommunityMobileModalAppDownload = "$[Community] - [Mobile modal app download clicked]",
  CommunityActivityMobileAppDownload = "$[Community Activity] - [Mobile banner app download clicked]",
  CommunityPostBannerUSCISStatusExplorerClicked = "$[Community Post] - [Banner uscis status explorer clicked]",
  CommunityPostCommentBannerUSCISStatusExplorerClicked = "$[Community Post] - [Comment banner uscis status explorer clicked]",
  NavMobileAppDownload = "$[Nav] - [Mobile app download clicked]",
  USCISStatusExplorerDesktopAppDownloadClicked = "$[USCIS Status Explorer] - [Desktop app download clicked]",
  USCISStatusExplorerDesktopPhoneNumberSubmitted = "$[USCIS Status Explorer] - [Desktop phone number submitted]",
  USCISStatusExplorerMobileAppDownloadClicked = "$[USCIS Status Explorer] - [Mobile app download clicked]",
  USCISStatusExplorerTooltipCTLClicked = "$[USCIS Status Explorer] - [Tooltip CTL clicked]",
  USCISStatusExplorerCTLSubmitted = "$[USCIS Status Explorer] - [CTL submitted]",
  USCISStatusExplorerMobileNativeAdClicked = "$[USCIS Status Explorer] - [Mobile native ad clicked]",
  USCISStatusExplorerDesktopNativeAdClicked = "$[USCIS Status Explorer] - [Desktop native ad clicked]",
  USCISMobileBannerAppDownload = "$[USCIS Status Explorer] - [Mobile top banner app download clicked]",
  TrendGraphCategoryToCaseTrackerNo = "$[Trend Graph] - [Category to cases tracker no]",
  TrendGraphCategoryToCaseTrackerYes = "$[Trend Graph] - [Category to cases tracker yes]",
  TrendGraphMobileBannerAppDownload = "$[Trend Graph] - [Mobile top banner app download clicked]",
  TrendGraphNavigateOffsetClicked = "$[Trend Graph] - [Navigate offset clicked]",
  TrendGraphDesktopAppDownloadClicked = "$[Trend Graph] - [Desktop app download clicked]",
  TrendGraphDesktopPhoneNumberSubmitted = "$[Trend Graph] - [Desktop phone number submitted]",
  TrendGraphMobileAppDownloadClicked = "$[Trend Graph] - [Mobile app download clicked]",
  HubSectionCaseTrackerClicked = "$[Hub] - [Section case tracker clicked]",
  HubSectionCaseAnalysisProClicked = "$[Hub] - [Section case analysis pro clicked]",
  HubSectionConsultationClicked = "$[Hub] - [Section consultation clicked]",
  HubSectionMockInterviewClicked = "$[Hub] - [Section mock interview clicked]",
  HubCTACommunityClicked = "$[Hub] - [CTA Community clicked]",
  HubResourceLinkClicked = "$[Hub] - [Resource link clicked]",
  HubPromotionBannerClicked = "$[Hub] - [Promotion banner clicked]",
  HubMobileTopAppDownloadClicked = "$[Hub] - [Mobile top app download clicked]",
  HubHeroPhoneNumberSubmitted = "$[Hub] - [Hero Desktop phone number submitted]",
  HubHeroDesktopAppDownload = "$[Hub] - [Hero Desktop app download clicked]",
  HubHeroMobileAppDownload = "$[Hub] - [Hero Mobile app download clicked]",
  RemittanceHomeSendMoneyClicked = "$[Remittance] - [Home send money clicked]",
  RemittanceHomeSelectCountryClicked = "$[Remittance] - [Home select country clicked]",
  RemittanceHomeBeneficiaryCardClicked = "$[Remittance] - [Home beneficiary card clicked]",
  RemittanceSelectCountryItemClicked = "$[Remittance] - [Select country item clicked]",
  RemittanceAmountSubmitted = "$[Remittance] - [Amount submitted]",
  RemittanceAmountSelectCountryClicked = "$[Remittance] - [Amount select country clicked]",
  RemittancePayerNameSubmitted = "$[Remittance] - [Payer name submitted]",
  RemittancePayerAddressSubmitted = "$[Remittance] - [Payer address submitted]",
  RemittancePayerMoreInfoSubmitted = "$[Remittance] - [Payer more info submitted]",
  RemittancePayerMoreInfoUpdateClicked = "$[Remittance] - [Payer more info update clicked]",
  RemittancePayerAgreeTOSClicked = "$[Remittance] - [Payer agree TOS clicked]",
  RemittancePaymentDetailsBankAccountClicked = "$[Remittance] - [Payment details bank account clicked]",
  RemittancePaymentDetailsConnectClicked = "$[Remittance] - [Payment details connect clicked]",
  RemittancePaymentDetailsManageClicked = "$[Remittance] - [Payment details manage clicked]",
  RemittancePaymentDetailsAddNewBankAccountClicked = "$[Remittance] - [Payment details add new bank account clicked]",
  RemittanceBeneficiaryNameSubmitted = "$[Remittance] - [Beneficiary name submitted]",
  RemittanceBeneficiaryBankAccountBeneficiaryCardClicked = "$[Remittance] - [Baneficiary bank account beneficiary card clicked]",
  RemittanceBeneficiaryBankAccountSubmitted = "$[Remittance] - [Beneficiary bank account submitted]",
  RemittanceBeneficiaryAddressSubmitted = "$[Remittance] - [Beneficiary address submitted]",
  RemittanceReasonSubmitted = "$[Remittance] - [Reason submitted]",
  RemittanceConfirmTransferSubmitted = "$[Remittance] - [Confirm transfer submitted]",
  RemittanceTransferListItemClicked = "$[Remittance] - [Transfer list item clicked]",
  RemittanceTransferDetailReceiptClicked = "$[Remittance] - [Transfer detail receipt clicked]",
  RemittanceTransferDetailCancelClicked = "$[Remittance] - [Transfer detail cancel clicked]",
}

export enum ToastType {
  Info,
  Warn,
  Success,
  Mobile,
}

export enum SortTypeCommunity {
  Recent = "date",
  MostLiked = "likes",
  MostCommented = "comments",
}

/**
 * Represents steps related N-400 self-application
 */
export enum ApplicationStep {
  CreateAccount = "createAccount",
  CompleteForms = "completeForms",
  Review = "review",
  Submit = "submit",
  USCISProcessing = "uscisProcessing",
}

import Fetch from "../../lib/fetch";

export default class SelfApplicationService extends Fetch {
  /**
   * Activates the user to be able to use the self-application feature
   */
  public async activateSelfApplicationUser(): Promise<void> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/users`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Create a new application
   * @param params
   */
  public async createApplication(
    params: ReqCreateApplication,
  ): Promise<ResCreateApplication> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * List all applications given a user
   *
   */
  public async getListApplication(): Promise<ResApplication[]> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications`,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Get a single application detail
   *
   */
  public async getApplication(
    applicationId: string,
  ): Promise<ResApplicationDetail> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications/${applicationId}`,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Update a single application detail
   *
   */
  public async updateApplication(
    applicationId: string,
    params: ReqUpdateApplication,
  ): Promise<ResApplicationDetail> {
    try {
      return await this.put(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications/${applicationId}`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Create a new review
   * @param params
   */
  public async createReview(
    applicationId: string,
    params: ReqCreateReview,
  ): Promise<ResCreateReview> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications/${applicationId}/reviews`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Upload files to a review
   * @param params
   */
  public async uploadFilesReview(
    reviewId: string,
    params: ReqUploadFilesReview,
  ): Promise<unknown> {
    try {
      const data = new FormData();
      params.files.forEach((file) => data.append("files", file, file.name));

      return await this.upload(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/reviews/${reviewId}/upload-files`,
        data,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Update a single application detail
   *
   */
  public async updateFeedback(
    feedbackId: number,
    params: ReqUpdateFeedback,
  ): Promise<ResApplicationDetail> {
    try {
      return await this.put(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/feedback/${feedbackId}`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }

  public async getPaymentIntent(
    applicationId: string,
  ): Promise<ResSAPaymentIntent> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications/${applicationId}/payment-intent`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async createPaymentIntent(
    applicationId: string,
  ): Promise<ResSAPaymentIntent> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications/${applicationId}/payment-intent`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }

  public async getEligibilityTestResult(
    caseType: "N-400",
  ): Promise<ResEligibilityTestResult> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/eligibility-result`,
        { caseType },
      );
    } catch (e) {
      throw e;
    }
  }

  public async updateReceiptNumber(
    applicationId: string,
    params: ReqUpdateReceiptNumber,
  ): Promise<ResApplicationDetail> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/self-application/applications/${applicationId}/cases`,
        params,
      );
    } catch (e) {
      throw e;
    }
  }
}

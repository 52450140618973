import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import sum from "ramda/src/sum";
import prop from "ramda/src/prop";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import IconClose from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import IconInfo from "lib/assets/toast-info.svg";
import IconSuccess from "lib/assets/toast-success.svg";
import { useLayout } from "lib/contexts/layoutContext";
import { ToastType } from "lib/enums";

const TOAST_WRAPPER_ID = "toastsWrapper";

const Toasts = () => {
  const { state, doCloseToast } = useLayout();

  return (
    <div id={TOAST_WRAPPER_ID}>
      {state.dataListToast.map((toast, i) => (
        <Toast key={toast.id} index={i} onExited={doCloseToast} {...toast} />
      ))}
    </div>
  );
};

interface ToastProps {
  index: number;
  id: string;
  duration?: number | null;
  content?: React.ReactNode;
  type?: ToastType;
  showClose?: boolean;
  onExited: (toastId: string, toastHeight: number) => void;
}

const Toast: React.FC<ToastProps> = ({
  index,
  id,
  duration,
  content,
  type,
  showClose,
  onExited,
}) => {
  // State. Is toast open?
  const [open, setOpen] = useState(true);
  // State. Vertical position of toast.
  const [y, setY] = useState(0);
  /**
   * Handles toast close.
   */
  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  // Update vertical position on new index.
  useEffect(() => {
    const wrapper = document.getElementById(TOAST_WRAPPER_ID);
    if (wrapper) {
      const toasts = Array.from(wrapper.children);
      const toastPosition = computeToastPosition(index, toasts);
      setY(toastPosition);
    }
  }, [index]);

  return (
    <StyledSnackbar
      type={type}
      y={y}
      open={open}
      ClickAwayListenerProps={{
        onClickAway: () => {
          // noop
        },
      }}
      TransitionComponent={Slide}
      autoHideDuration={duration === null ? null : duration || 3 * 1000}
      message={
        <ToastContent
          type={type}
          message={content}
          showClose={duration === null || showClose}
          onClose={handleOnClose}
        />
      }
      onClose={handleOnClose}
      onExited={(node) => onExited(id, node.offsetHeight)}
    />
  );
};

const StyledSnackbar = styled(Snackbar)<{ y: number; type: ToastType }>`
  transition: transform 150ms ease-in-out;
  .MuiSnackbarContent-root {
    overflow: hidden;
    padding: 0;
    max-width: 500px;
    color: ${({ theme, type }) =>
      type === ToastType.Mobile
        ? theme.palette.common.white
        : theme.palette.text.primary};
    background-color: ${({ theme, type }) =>
      type === ToastType.Mobile ? "#5A8FFF" : theme.palette.common.white};
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(${({ y }) => -y}px) !important;
  }
  .MuiSnackbarContent-message {
    padding: 0 20px;
    width: 100%;
  }
`;

interface ToastContentProps {
  type?: ToastType;
  message?: React.ReactNode;
  showClose?: boolean;
  onClose?: () => void;
}

const ToastContent: React.FC<ToastContentProps> = ({
  type,
  message,
  showClose,
  onClose,
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <ToastIcon type={type} />
      <Box
        css={`
          padding: 18px 20px;
          flex: 1;
          font-size: 14px;
          line-height: 22px;
          overflow: hidden;
          text-align: ${type === ToastType.Mobile ? "center" : "left"};
        `}
      >
        {message}
      </Box>
      {showClose && (
        <IconCloseWrapper onClick={onClose}>
          <IconClose fontSize="small" />
        </IconCloseWrapper>
      )}
    </Box>
  );
};

interface ToastIconProps {
  type?: ToastType;
}

const ToastIcon: React.FC<ToastIconProps> = ({ type }) => {
  if (type === ToastType.Success) {
    return <IconSuccess />;
  }
  if (type === ToastType.Warn) {
    return <IconWarn />;
  }
  if (type === ToastType.Info) {
    return <IconInfo />;
  }
  return null;
};

const IconCloseWrapper = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grey[200]};
  transition: color ${({ theme }) => theme.transitions.duration.shortest}ms
    ${({ theme }) => theme.transitions.easing.easeInOut};
  &:hover {
    color: ${({ theme }) => theme.palette.grey[300]};
  }
`;

const IconWarn = styled(IconInfo)`
  path {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;

/**
 * Computes vertical position of toast at given index.
 *
 * @param index Index of target toast.
 * @param siblings List of all toasts.
 */
const computeToastPosition = (index: number, toasts: Element[]) => {
  const olderToasts = toasts.slice(0, index) as HTMLElement[];
  const sumSpacings = olderToasts.length * 10;
  const sumHeights = sum(olderToasts.map(prop("offsetHeight")));

  return sumHeights + sumSpacings;
};

export default Toasts;

import { useCallback, useEffect } from "react";
import { useAuth } from "domain/auth/authContext";
import { AsyncStatus } from "lib/enums";

const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;

const Intercom = () => {
  const { bootIntercom } = useIntercom();

  // Boot Intercom.
  useEffect(() => {
    bootIntercom();
  }, [bootIntercom]);

  return null;
};

export default Intercom;

export const useIntercom = () => {
  const {
    state: {
      hasValidatedSavedToken,
      dataUser,
      dataProfile,
      status,
      isLoggedIn,
    },
  } = useAuth();

  /**
   * Boots Intercom. Sends user properties if possible.
   */
  const bootIntercom = useCallback(
    (options?: { purchased?: boolean }) => {
      if (!window.Intercom) {
        // Wait for `window.Intercom` to become available.
        setTimeout(bootIntercom, 1000);
      } else {
        // Wait for token validation to be completed.
        if (hasValidatedSavedToken) {
          // Wait for user data to be loaded.
          if (dataUser) {
            // Wait for user profile data to be loaded.
            if (
              status.dataProfile === AsyncStatus.Success ||
              status.dataProfile === AsyncStatus.Error
            ) {
              window.Intercom("shutdown");
              window.Intercom("boot", {
                app_id: intercomAppId,
                user_id: dataUser.id,
                verified_email: dataUser.i765ApplicationInfo?.verifiedEmail,
                email: dataUser.email,
                name: dataProfile
                  ? dataProfile.firstName + " " + dataProfile.lastName
                  : undefined,
                avatar: dataProfile?.profileImage
                  ? { type: "avatar", image_url: dataProfile.profileImage }
                  : undefined,
                activated_self_application: !!(
                  options?.purchased || dataUser.selfApplicationInfo
                ),
                purchased_self_application: !!(
                  options?.purchased ||
                  dataUser.selfApplicationInfo?.isPurchased
                ),
                lawfully_ops:
                  options?.purchased || dataUser.selfApplicationInfo
                    ? `https://ops${
                        process.env.NEXT_PUBLIC_DIST_ENV === "production"
                          ? ""
                          : "-test"
                      }.lawfully.com/users/${dataUser.id}`
                    : null,
              });
            }
          } else {
            window.Intercom("boot", {
              app_id: intercomAppId,
            });
          }
        }
      }
    },
    [dataProfile, dataUser, hasValidatedSavedToken, status.dataProfile],
  );

  const convertVisitor = useCallback(async () => {
    if (isLoggedIn) return;
    const visitorId = window.Intercom?.("getVisitorId");
    if (!visitorId) {
      // It takes time for `visitorId` to be available.
      return new Promise((resolve) => {
        setTimeout(() => resolve(convertVisitor()), 1000);
      });
    }
    await fetch("/api/intercom/visitors/convert", {
      method: "post",
      body: JSON.stringify({ visitorId }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }, [isLoggedIn]);

  return {
    bootIntercom,
    convertVisitor,
  };
};

import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useEffect,
  useCallback,
} from "react";
import { setCookie, destroyCookie } from "nookies";
import firebase from "firebase/app";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/node";
import AuthService from "domain/auth/authService";
import { useLayout } from "lib/contexts/layoutContext";
import { FirebaseContext } from "lib/contexts/firebaseContext";
import { AsyncStatus } from "lib/enums";
import { resizeSquareImage } from "lib/image";
import useIsFromTrackerApp from "lib/hooks/useIsFromTrackerApp";

interface AuthStore {
  hasValidatedSavedToken: boolean;
  isLoggedIn: boolean;
  dataUser: ResUser | ResUserSocial | null;
  dataProfile: ResProfile | null;
  status: {
    dataProfile: AsyncStatus;
  };
}

type AuthAction =
  | { type: "SET_HAS_VALIDATED_SAVED_TOKEN"; val: boolean }
  | { type: "SET_IS_LOGGED_IN"; val: boolean }
  | { type: "SET_USER"; data: ResUser | ResUserSocial }
  | {
      type: "SET_PROFILE";
      val: ResProfile;
    }
  | {
      type: "SET_STATUS_PROFILE";
      val: AsyncStatus;
    };

const AuthContext = createContext<
  [AuthStore, React.Dispatch<AuthAction>] | null
>(null);

const AuthProvider: React.FC<any> = ({ children, ...props }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return (
    <AuthContext.Provider value={value} {...props}>
      <InitData>{children}</InitData>
    </AuthContext.Provider>
  );
};

const InitData: React.FC = (props) => {
  const { state, doValidateSavedToken, doRefreshToken, doGetProfile } =
    useAuth();
  const isFromTrackerApp = useIsFromTrackerApp();

  useEffect(() => {
    // Wait for `isFromTrackerApp` value is determined.
    if (isFromTrackerApp === null) {
      return;
    }
    if (!state.hasValidatedSavedToken) {
      // TEMPORARY FIX: Do not use stored token from previous session in webview.
      if (isFromTrackerApp) {
        localStorage.removeItem("token");
      }
      doValidateSavedToken();
    }
  }, [state.hasValidatedSavedToken, doValidateSavedToken, isFromTrackerApp]);

  // Refresh auth-token periodically.
  useEffect(() => {
    let interval: number;
    if (state.dataUser) {
      interval = window.setInterval(doRefreshToken, 60 * 60 * 1000); // 1 hour
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [doRefreshToken, state.dataUser]);

  useEffect(() => {
    async function initProfile() {
      return await doGetProfile();
    }
    if (state.status.dataProfile === AsyncStatus.Initial && state.dataUser) {
      initProfile();
    }
  }, [doGetProfile, state.dataUser, state.status.dataProfile]);

  return <>{props.children}</>;
};

function useAuth() {
  const context = useContext(AuthContext);
  const { doOpenAlert } = useLayout();
  const firebaseApp = useContext(FirebaseContext);

  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  const [state, dispatch] = context;
  const service = useMemo(
    () =>
      new AuthService(
        state.dataUser?.token ? { token: state.dataUser?.token } : undefined,
      ),
    [state.dataUser?.token],
  );

  /**
   * Logs the user in
   */
  const doLogin = useCallback(
    async (data: FormDataLogin) => {
      const user = await service.loginEmail({
        email: data.email,
        password: data.password,
        version: "3.0.0",
      });
      dispatch({
        type: "SET_USER",
        data: user,
      });
      setCookie(null, "token", user.token, { path: "/" });
      localStorage.setItem("token", user.token);
      localStorage.removeItem("provider");
    },
    [service, dispatch],
  );

  /**
   * Logs the user in via social OAuth credentials
   */
  const doLoginSocial = useCallback(
    async (accessToken: string, provider: OAuthProvider) => {
      const user = await service.loginSocial({
        accessToken,
        provider,
        version: "3.0.0",
      });
      setCookie(null, "token", user.token, { path: "/" });
      localStorage.setItem("token", user.token);
      localStorage.setItem("provider", user.provider);
      dispatch({
        type: "SET_USER",
        data: user,
      });
      return user;
    },
    [service, dispatch],
  );

  /**
   * Logs the user out
   */
  const doLogOut = useCallback(() => {
    destroyCookie(null, "token", { path: "/" });
    localStorage.removeItem("token");
    localStorage.removeItem("provider");
    window.Intercom?.("shutdown");
    window.location.href = window.location.origin;
  }, []);

  const doGetProfile = useCallback(async () => {
    dispatch({
      type: "SET_STATUS_PROFILE",
      val: AsyncStatus.Requested,
    });
    try {
      const data = await service.getProfile();
      dispatch({
        type: "SET_PROFILE",
        val: data,
      });
      dispatch({
        type: "SET_STATUS_PROFILE",
        val: AsyncStatus.Success,
      });
    } catch (e) {
      dispatch({
        type: "SET_STATUS_PROFILE",
        val: AsyncStatus.Error,
      });
      if (e.name !== "CommunityProfileNotFound") {
        throw e;
      }
    }
  }, [service, dispatch]);

  const doUpdateProfile = useCallback(
    async (data: {
      profileImage?: string;
      firstName: string;
      lastName: string;
    }) => {
      try {
        const profile = await service.updateProfile(data);
        dispatch({
          type: "SET_PROFILE",
          val: profile,
        });
        return profile;
      } catch (e) {
        throw e;
      }
    },
    [service, dispatch],
  );

  /**
   * Refreshes token
   */
  const doRefreshToken = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = await service.refreshToken(token);
      setCookie(null, "token", user.token, { path: "/" });
      localStorage.setItem("token", user.token);
      dispatch({
        type: "SET_USER",
        data: {
          ...user,
          provider: localStorage.getItem("provider") as OAuthProvider,
        },
      });
      return user;
    }
    return null;
  }, [service, dispatch]);

  const doValidateSavedToken = useCallback(async () => {
    try {
      await doRefreshToken();
      dispatch({
        type: "SET_HAS_VALIDATED_SAVED_TOKEN",
        val: true,
      });
    } catch (err) {
      console.log(err.name);
      if (err.name === "TokenExpired" || err.name === "TokenInvalid") {
        // Log user out on authentication error.
        doLogOut();
      }
    }
  }, [dispatch, doRefreshToken, doLogOut]);

  /**
   * If there is a pending credential retireved from `auth/account-exists-with-different-credential`,
   * links it with the current user.
   */
  const doLinkUserWithPendingCredential = async (user: firebase.User) => {
    const pendingCredential = JSON.parse(
      sessionStorage.getItem("pendingCredential"),
    );
    if (pendingCredential) {
      sessionStorage.removeItem("pendingCredential");
      await user.linkWithCredential(pendingCredential);
    }
  };

  const doLoginWithRedirect = useCallback(
    async (provider: "google" | "facebook") => {
      await firebaseApp
        .auth()
        .signInWithRedirect(
          provider === "google"
            ? new firebase.auth.GoogleAuthProvider()
            : new firebase.auth.FacebookAuthProvider(),
        );
    },
    [firebaseApp],
  );

  /**
   * Handles Firebase auth redirect result.
   */
  const doHandleRedirectResult = useCallback(async () => {
    const auth = firebaseApp.auth();
    try {
      const { user, credential } = await auth.getRedirectResult();
      if (user && credential) {
        await doLinkUserWithPendingCredential(user);
        const { accessToken, providerId } =
          credential as firebase.auth.OAuthCredential;
        return await doLoginSocial(
          accessToken,
          providerId.includes("google") ? "google" : "facebook",
        );
      }
    } catch (err) {
      if (err.code === "auth/account-exists-with-different-credential") {
        sessionStorage.setItem(
          "pendingCredential",
          JSON.stringify(err.credential),
        );
        // Find out providers that are associated with the email address.
        // e.g. ["google.com", "password"]
        const providers = await auth.fetchSignInMethodsForEmail(err.email);
        // Ask user to log in with another provider.
        if (providers?.[0]) {
          const provider = providers[0].includes("password")
            ? "email/password"
            : providers[0];
          doOpenAlert({
            content: `An account already exists. Try logging in with: ${provider}`,
          });
        }
      } else {
        doOpenAlert({
          content:
            err.message || `There was a problem. Please try again shortly.`,
        });
      }
    }
  }, [firebaseApp, doLoginSocial, doOpenAlert]);

  /**
   * Changes password
   */
  const changePassword = useCallback(
    async (oldPassword: string, newPassword: string) => {
      await service.changePassword({
        email: state.dataUser?.email || "",
        oldPassword,
        newPassword,
      });
    },
    [service, state.dataUser],
  );

  /**
   * Resets password and sends email (forgot password)
   */
  const resetPassword = useCallback(
    async (email: string) => {
      await service.resetPassword(email);
    },
    [service],
  );

  /**
   * Sign up (email)
   */
  const signUp = useCallback(
    async (email: string, password: string, token?: string) => {
      await service.signUp({
        email,
        password,
        token,
      });
    },
    [service],
  );

  /**
   * Sign up using employee code
   */
  const signUpEmployeeCode = useCallback(
    async (
      email: string,
      password: string,
      organizationId: string,
      code: string,
    ) => {
      await service.signUpEmployeeCode({
        email,
        password,
        organizationId,
        code,
      });
    },
    [service],
  );

  /**
   * Sign up using lawyer's office email
   */
  const signUpLawyerCode = useCallback(
    async (email: string, password: string, lawyerOfficeEmail: string) => {
      await service.signUpLawyerCode({
        email,
        password,
        lawyerOfficeEmail,
      });
    },
    [service],
  );

  /**
   * Send verification code to provided mobile number
   */
  const doSendMobileVerificationCode = useCallback(
    async (mobileNumber: string) => {
      return await service.sendMobileVerificationCode({ mobileNumber });
    },
    [service],
  );

  /**
   *  Check verification code
   */
  const doCheckMobileVerificationCode = useCallback(
    async (mobileCountry: string, mobileNumber: string, code: string) => {
      return await service.checkMobileVerificationCode({
        mobileNumber: mobileCountry + mobileNumber,
        code,
      });
    },
    [service],
  );

  /**
   * Send verification code to provided mobile number
   */
  const doSendVerificationEmail = useCallback(
    async (email: string) => {
      return await service.sendVerificationEmail({ email });
    },
    [service],
  );

  /**
   * validate email with token
   */
  const doVerifyEmailWithToken = useCallback(
    async (token: string) => {
      return await service.verifyEmailWithToken(token);
    },
    [service],
  );

  /**
   *  Updates user data
   */
  const doUpdateUser = useCallback(
    async (data: Partial<ReqUpdateUser>) => {
      if (state.dataUser) {
        await service.updateUser(state.dataUser.id, data);
      }
    },
    [service, state.dataUser],
  );

  const doUploadProfileImage = useCallback(
    async (image: File) => {
      const resized = await resizeSquareImage(image, 300);
      const val = await service.uploadProfileImage(resized);
      dispatch({
        type: "SET_PROFILE",
        val,
      });
    },
    [dispatch, service],
  );

  // Capture the user for Sentry.
  useEffect(() => {
    Sentry.setUser(state.dataUser ? { id: String(state.dataUser.id) } : null);
  }, [state.dataUser]);

  return {
    state,
    dispatch,
    doLogin, // action
    doLoginSocial,
    doLogOut,
    doRefreshToken,
    doValidateSavedToken,
    doSendMobileVerificationCode,
    doCheckMobileVerificationCode,
    doUpdateUser,
    doLoginWithRedirect,
    doHandleRedirectResult,
    changePassword,
    resetPassword,
    signUp,
    signUpEmployeeCode,
    signUpLawyerCode,
    doSendVerificationEmail,
    doVerifyEmailWithToken,
    doGetProfile,
    doUpdateProfile,
    doUploadProfileImage,
    service,
  };
}

const initialState = {
  hasValidatedSavedToken: false,
  isLoggedIn: false,
  dataUser: null,
  dataProfile: null,
  status: {
    dataProfile: AsyncStatus.Initial,
  },
};

function authReducer(state: AuthStore, action: AuthAction): AuthStore {
  switch (action.type) {
    case "SET_HAS_VALIDATED_SAVED_TOKEN": {
      return {
        ...state,
        hasValidatedSavedToken: action.val,
      };
    }

    case "SET_IS_LOGGED_IN": {
      return {
        ...state,
        isLoggedIn: action.val,
      };
    }

    case "SET_USER": {
      return {
        ...state,
        isLoggedIn: true,
        dataUser: action.data,
      };
    }

    case "SET_PROFILE": {
      return {
        ...state,
        dataProfile: action.val,
        status: {
          ...state.status,
          dataProfile: AsyncStatus.Success,
        },
      };
    }

    case "SET_STATUS_PROFILE": {
      return {
        ...state,
        status: {
          ...state.status,
          dataProfile: action.val,
        },
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

/**
 * Prevents logged-in user from accessing the current page.
 * @returns {boolean} Can the user access the current page?
 */
const useRedirectIfLoggedIn = (): boolean => {
  const { query, replace } = useRouter();
  const {
    state: { isLoggedIn, hasValidatedSavedToken },
  } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      if (typeof query.redirect === "string") {
        replace(query.redirect);
      } else {
        replace("/");
      }
    }
  }, [isLoggedIn, query.redirect, replace]);

  return hasValidatedSavedToken && !isLoggedIn;
};

export { AuthProvider, useAuth, useRedirectIfLoggedIn };

import { useEffect, useMemo, useState } from "react";

/**
 * Determines if the current session is run by Lawfully Tracker app.
 * @param userAgent User agent string from server (if any).
 * @returns {boolean | null} `null` -> undetermined
 */
const useIsFromTrackerApp = (userAgent = "") => {
  const [userAgentString, setUserAgentString] = useState(userAgent);

  /**
   * Is the page currently embeded in Tracker app?
   *   - Assume the page is embeded in Tracker, if user agent string includes
   *     "Lawfully-Tracker", which is explicitly set by the app.
   */
  const isFromTrackerApp = useMemo(() => {
    if (!userAgentString) {
      return null;
    }
    return userAgentString.includes("Lawfully-Tracker");
  }, [userAgentString]);

  useEffect(() => {
    if (!userAgentString) {
      setUserAgentString(window.navigator.userAgent);
    }
  }, [userAgentString]);

  return isFromTrackerApp;
};

export default useIsFromTrackerApp;

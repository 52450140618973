import Fetch from "../../lib/fetch";

export default class RequestService extends Fetch {
  public async getListLawyerReview(
    lawyerId: string,
    pageSize: number,
    pageNumber: number,
  ) {
    try {
      const res = await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/lawyers/reviews/${lawyerId}`,
        { pageSize, pageNumber },
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  public async getListLawyer(): Promise<ResLawyerListItem[]> {
    try {
      const res = await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/lawyers/profiles`,
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves saved payment-method.
   *
   * @returns Payment-method information.
   * @memberof RequestService
   */
  public async getPaymentMethod(): Promise<ResPaymentMethod> {
    try {
      const res = await this.get("/payment");
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves request-information used in "book" screen.
   *
   * @param lawyerId Lawyer ID in hashed form.
   * @returns Request-information.
   * @memberof RequestService
   */
  public async getRequestInfo(lawyerId: string): Promise<ResRequestInfo> {
    try {
      const res = await this.get(`/lawyers/${lawyerId}/consultation-info`);
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * @param lawyerId Retrieves lawyer profile.
   *
   * @param lawyerId Lawyer ID in hashed form.
   * @returns Lawyer profile.
   * @memberof RequestService
   */
  public async getLawyerInfo(lawyerId: string): Promise<ResLawyerProfile> {
    try {
      const res = this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/lawyers/profiles/${lawyerId}`,
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves lawyer's available timeslots
   *
   * @param lawyerId Lawyerd ID in hashed form
   * @param date date at which timeslot is queried
   * @param offset offset from utc e.g. -9
   */
  public async getListLawyerTimeslot(
    lawyerId: string,
    date: DateString,
    offset: number,
  ): Promise<ResListLaywerTimeslot> {
    try {
      const res = await this.get(`/lawyers/${lawyerId}/timeslot`, {
        date,
        offset,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves list of consultation areas.
   *
   * @returns List of consultation areas.
   * @memberof RequestService
   */
  public async getListConsultationArea(): Promise<ResConsultationArea[]> {
    try {
      const res = await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/common/v1/speciality-types`,
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Starts card-setup process. Server creates Stripe `SetupIntent` and returns `clientSecret`.
   *
   * @returns Client-secret (from setup-intent).
   * @memberof RequestService
   */
  public async getCardSetupSecret(): Promise<string> {
    try {
      const res = await this.post("/payment/client-secret", {});
      return res.clientSecret;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Sends created payment-method to server.
   *
   * @param paymentMethodId Payment-method ID for card saved in Stripe.
   * @param cardHoldersName Card-holder's name
   * @memberof RequestService
   */
  public async savePaymentMethod(
    paymentMethodId: string,
    cardHoldersName: string,
  ): Promise<ResPaymentMethod> {
    try {
      const res = await this.post("/payment", {
        paymentMethodId,
        cardHoldersName,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Creates consultation request.
   *
   * @param params Parameters.
   * @returns Consultation.
   * @memberof RequestService
   */
  public async createConsultation(
    params: ReqConsultation,
  ): Promise<ResConsultationDetail> {
    try {
      const res = await this.post("/consultations", {
        ...params,
        source: "web",
      });
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Post request for coupon validation.
   *
   * @param params ReqPostCouponValidate
   * @returns ResPostCouponValidate
   */
  public async postCouponValidate(
    params: ReqPostCouponValidate,
  ): Promise<ResPostCouponValidate> {
    try {
      const res = await this.post("/coupon", {
        ...params,
      });
      return res;
    } catch (e) {
      throw e;
    }
  }
}

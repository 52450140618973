import React, { createContext, ReactChild } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const FirebaseContext = createContext<firebase.app.App | null>(null);
const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

const FirebaseProvider = ({ children }: { children: ReactChild }) => {
  if (!firebase.apps.length) {
    // SHOULD BE THE FIRST APP TO INITIALIZE
    firebase.initializeApp(config);
  }
  return (
    <FirebaseContext.Provider value={firebase.app()}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseProvider, FirebaseContext };

import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useAuth } from "domain/auth/authContext";
import { CustomEventLabel } from "lib/enums";
import { useRouter } from "next/router";

const EventContext = createContext<{
  tag: ((eventLabel: CustomEventLabel, eventData?: any) => void) | null;
  track: (eventLabel: string, eventData?: any) => void;
}>({ tag: null, track: null });

function EventProvider(props: any) {
  const {
    state: { dataUser },
  } = useAuth();
  const { events: routerEvents } = useRouter();

  const tag = useCallback((eventLabel: CustomEventLabel, eventData?: any) => {
    // flush eventData field
    window.dataLayer?.push({
      eventData: null,
    });
    window.dataLayer?.push({
      event: eventLabel,
      ...(eventData ? { eventData } : {}),
    });
  }, []);

  /**
   * Set user identity for Segment analytics
   */
  useEffect(() => {
    if (dataUser?.id) {
      // Set user id
      tag(CustomEventLabel.Identify, String(dataUser.id));
    }
  }, [dataUser?.id, tag]);

  useEffect(() => {
    const handleRouteChange = () => {
      window.Intercom?.("update", {
        last_request_at: Math.trunc(new Date().getTime() / 1000),
      });
      tag(CustomEventLabel.RouteChange);
    };
    routerEvents.on("routeChangeComplete", handleRouteChange);

    return () => {
      routerEvents.off("routeChangeComplete", handleRouteChange);
    };
  }, [routerEvents, tag]);

  /**
   * Trigger track event for Segment analytics?
   */
  const track = useCallback(
    (eventLabel: CustomEventLabel, eventData?: any) => {
      tag(eventLabel, eventData);
    },
    [tag],
  );

  const value = useMemo(
    () => ({
      tag,
      track,
    }),
    [tag, track],
  );

  return <EventContext.Provider value={value} {...props} />;
}

function useEvent() {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error(`useEvent must be used within a EventProvider`);
  }

  const { tag, track } = context;

  return { tag, track };
}

export { EventProvider, useEvent };

import { useContext } from "react";
import {
  createGlobalStyle,
  ThemeProvider as StyledThemeProvider,
  ThemeContext,
} from "styled-components";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import type {} from "styled-components/cssprop"; // required to enable css prop on jsx elements

export const ThemeProvider: React.FC = ({ children }) => {
  return (
    <>
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </MuiThemeProvider>
      </StyledThemeProvider>
    </>
  );
};

export const useTheme = () => useContext(ThemeContext);

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 860,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ["DM Sans", "Roboto", "sans-serif"].join(", "),
    h1: {
      lineHeight: "39px",
      fontSize: 30,
      fontWeight: "bold",
    },
    h2: {
      lineHeight: "36px",
      fontSize: 24,
      fontWeight: "bold",
    },
    h3: {
      lineHeight: "30px",
      fontSize: 20,
      fontWeight: "bold",
    },
    h4: {
      lineHeight: "24px",
      fontSize: 16,
      fontWeight: "bold",
    },
    body1: {
      lineHeight: "24px",
      fontSize: 16,
    },
    body2: {
      lineHeight: "22px",
      fontSize: 14,
    },
    caption: {
      lineHeight: "18px",
      fontSize: 12,
    },
  },
  palette: {
    text: {
      primary: "#000000",
    },
    primary: {
      main: "#195dee",
    },
    error: {
      main: "#ff3333",
    },
    background: {
      default: "#fff",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    grey: {
      50: "#f3f4f8",
      100: "#eeeeee",
      200: "#dddddd",
      300: "#bbbbbb",
      400: "#aaaaaa",
      500: "#999999",
      600: "#666666",
    },
    blueGrey: {
      100: "#f1f3f8",
      200: "#e3e7f1",
      300: "#e3ecff",
    },
  },
  spacing: 5,
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const GlobalStyles = createGlobalStyle`
  html, body {
    font-family: "DM Sans", "Roboto", sans-serif;
    background-color: white;
    color: ${({ theme }) => theme.palette.text.primary};
    @media only screen and (min-resolution: 192dpi) {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    /**
     * Disable text inflation. https://developer.mozilla.org/en-US/docs/Web/CSS/text-size-adjust#basic_disabling_usage
     */
    text-size-adjust: none;
  }
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    ${({ theme: { palette, transitions } }) => `
      color: ${palette.primary.main};
      transition: color ${transitions.duration.shortest}ms ${transitions.easing.easeInOut};
      &:hover {
        color: ${palette.primary.dark};
      }
    `}
  }
  hr {
    margin: 10px 0;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.palette.grey[100]};
  }
  button {
    margin: 0;
    padding: 0;
    font-family: "DM Sans", "Roboto", sans-serif;
    font-size: 14px;
    color: black;
  }
  input,
  textarea {
    border-radius: 0;
  }
  /**
   * This will hide the focus indicator if the element receives focus via the mouse,
   * but it will still show up on keyboard focus. See https://github.com/WICG/focus-visible
   */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
`;

import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import SelfApplicationService from "domain/self-applications/selfApplicationService";
import { useAuth } from "domain/auth/authContext";
import { loadStripe } from "@stripe/stripe-js";
import { AsyncStatus } from "lib/enums";
import { mutate } from "swr";

interface SelfApplicationStore {
  dataPaymentIntent: ResSAPaymentIntent | null;
  isModalPaymentVisible: boolean;
  imgModalSrc: string | null;
}

type SelfApplicationAction =
  | {
      type: "SET_SA_PAYMENT_INTENT";
      val: ResSAPaymentIntent;
    }
  | {
      type: "SET_SA_PAYMENT_MODAL_VISIBLITY";
      val: boolean;
    }
  | {
      type: "SET_STATUS_APPLICATION_DETAIL";
      val: AsyncStatus;
    }
  | {
      type: "SET_SA_IMG_MODAL_SRC";
      val: string | null;
    };

const SelfApplicationContext = createContext<
  [SelfApplicationStore, React.Dispatch<SelfApplicationAction>] | null
>(null);

function SelfApplicationProvider(props: any) {
  const [state, dispatch] = useReducer(selfApplicationReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  const { children, ...rest } = props;

  return (
    <SelfApplicationContext.Provider value={value} {...rest}>
      {children}
    </SelfApplicationContext.Provider>
  );
}

function useSelfApplication() {
  const context = useContext(SelfApplicationContext);

  const {
    state: { dataUser },
    doRefreshToken,
  } = useAuth();

  const service = useMemo(
    () =>
      new SelfApplicationService(
        dataUser ? { token: dataUser.token } : undefined,
      ),
    [dataUser],
  );

  if (!context) {
    throw new Error(
      `useSelfApplication must be used within a SelfApplicationProvider`,
    );
  }

  const [state, dispatch] = context;

  const doActivateUser = useCallback(async () => {
    try {
      await service.activateSelfApplicationUser();
      return await doRefreshToken();
    } catch (e) {
      throw e;
    }
  }, [doRefreshToken, service]);

  const doCreateApplication = useCallback(
    async (caseType: "N-400", applicationName: string) => {
      try {
        await (dataUser?.selfApplicationInfo
          ? Promise.resolve()
          : doActivateUser()); // activate user if not activated yet
        return service.createApplication({
          caseType,
          applicationName,
        });
      } catch (e) {
        throw e;
      }
    },
    [dataUser, doActivateUser, service],
  );

  const doUpdateApplication = useCallback(
    async (applicationId: string, params: ReqUpdateApplication) => {
      try {
        return await service.updateApplication(applicationId, params);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doCreateReview = useCallback(
    async (applicationId: string, params: ReqCreateReview) => {
      try {
        return await service.createReview(applicationId, params);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doUploadFilesReview = useCallback(
    async (reviewId: string, params: ReqUploadFilesReview) => {
      try {
        return await service.uploadFilesReview(reviewId, params);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doUpdateFeedback = useCallback(
    async (feedbackId: number, params: ReqUpdateFeedback) => {
      try {
        const res = await service.updateFeedback(feedbackId, params);
        mutate(
          `/self-application/applications/${res.applicationId}`,
          res,
          false,
        );
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doGetPaymentIntent = useCallback(
    async (consultationId: string) => {
      // Delete stale payment-detail first.
      dispatch({
        type: "SET_SA_PAYMENT_INTENT",
        val: null,
      });
      try {
        const data = await service.getPaymentIntent(consultationId);
        dispatch({
          type: "SET_SA_PAYMENT_INTENT",
          val: data,
        });
      } catch (e) {
        if (e.name === "EntityWithStrIdNotFound") {
          // payment intent has not been attached yet
          const data = await service.createPaymentIntent(consultationId);
          dispatch({
            type: "SET_SA_PAYMENT_INTENT",
            val: data,
          });
        } else {
          throw e;
        }
      }
    },
    [service, dispatch],
  );

  const doProcessPayment = useCallback(async () => {
    if (!state.dataPaymentIntent) {
      throw new Error(`No payment intent.`);
    }
    const { clientSecret } = state.dataPaymentIntent;
    // [1]
    const stripe = await loadStripe(
      process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string,
    );
    if (!stripe) {
      throw new Error(
        `There was a problem. Please reload the page and try again.`,
      );
    }
    // [2]
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
    );
    // Handle processing error.
    if (error) {
      throw new Error(error.message);
    }
    // Handle unsuccessful charge. e.g. insufficient funds
    if (paymentIntent.status !== "succeeded") {
      throw new Error(
        paymentIntent?.last_payment_error?.message ||
          `Your card could not be charged.`,
      );
    }
    if (paymentIntent.status === "succeeded") {
      // payment processed successfully
      // close the payment modal
      dispatch({
        type: "SET_SA_PAYMENT_MODAL_VISIBLITY",
        val: false,
      });
    }
  }, [dispatch, state.dataPaymentIntent]);

  const doUpdateReceiptNumber = useCallback(
    async (applicationId: string, params: ReqUpdateReceiptNumber) => {
      try {
        return await service.updateReceiptNumber(applicationId, params);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  return {
    state,
    dispatch,
    doActivateUser,
    doCreateApplication,
    doCreateReview,
    doUploadFilesReview,
    doUpdateApplication,
    doUpdateFeedback,
    doGetPaymentIntent,
    doProcessPayment,
    doUpdateReceiptNumber,
    service,
  };

  // helper functions go beneath return statement
}

const initialState: SelfApplicationStore = {
  dataPaymentIntent: null,
  isModalPaymentVisible: false,
  imgModalSrc: null,
};

function selfApplicationReducer(
  state: SelfApplicationStore,
  action: SelfApplicationAction,
): SelfApplicationStore {
  switch (action.type) {
    case "SET_SA_PAYMENT_INTENT": {
      return {
        ...state,
        dataPaymentIntent: action.val,
      };
    }

    case "SET_SA_PAYMENT_MODAL_VISIBLITY": {
      return {
        ...state,
        isModalPaymentVisible: action.val,
      };
    }

    case "SET_SA_IMG_MODAL_SRC": {
      return {
        ...state,
        imgModalSrc: action.val,
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { SelfApplicationProvider, useSelfApplication };

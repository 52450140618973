import "focus-visible";
import "nprogress/nprogress.css";
import React, { useEffect } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import { AuthProvider } from "domain/auth/authContext";
import { NotificationProvider } from "domain/notification/notificationContext";
import { PushNotificationProvider } from "domain/push-notification/pushNotificationContext";
import { CommunityProvider } from "domain/community/communityContext";
import { ConsultationProvider } from "domain/consultations/consultationContext";
import { RequestProvider } from "domain/consultation-request/requestContext";
import { SelfApplicationProvider } from "domain/self-applications/selfApplicationContext";
import { ThemeProvider } from "lib/contexts/themeContext";
import { FirebaseProvider } from "lib/contexts/firebaseContext";
import { LayoutProvider } from "lib/contexts/layoutContext";
import { EventProvider } from "lib/contexts/eventContext";
import Intercom from "lib/components/Intercom";
import { FilingProvider } from "domain/filing/filingContext";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const { serverRuntimeConfig } = getConfig();
  const distDir = `${serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
    ],
    ignoreErrors: [
      // See lib/components/Prompt.
      "Prevent navigating away",
      // Filter out errors with no value.
      "Error: ResizeObserver loop limit exceeded",
      "Error: Network Error",
      "TypeError: Failed to fetch",
      "TypeError: canceled",
      "TypeError: NetworkError when attempting to fetch resource.",
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const TopProgressBar = dynamic(() => import("lib/components/TopProgressBar"), {
  ssr: false,
});

const LawfullyApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // Remove the server-side injected MUI CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <title>Lawfully: Your definitive US immigration companion</title>
        <meta
          name="description"
          content="Lawfully strives to be the one-stop solution for all your US immigration needs. Use Lawfully to start tracking your USCIS case. Lawfully provides status updates, decision date prediction, approval rates, RFE rates, and ranking of your case. Get your citizenship application reviewed by an immigration attorney. Connect with the US immigration community."
        />
      </Head>

      <ThemeProvider>
        <FirebaseProvider>
          <LayoutProvider>
            <AuthProvider>
              <EventProvider>
                <NotificationProvider>
                  <PushNotificationProvider>
                    <RequestProvider>
                      <ConsultationProvider>
                        <CommunityProvider>
                          <SelfApplicationProvider>
                            <FilingProvider>
                              <Component {...pageProps} />
                            </FilingProvider>
                            <Intercom />
                          </SelfApplicationProvider>
                        </CommunityProvider>
                      </ConsultationProvider>
                    </RequestProvider>
                  </PushNotificationProvider>
                </NotificationProvider>
              </EventProvider>
            </AuthProvider>
          </LayoutProvider>
        </FirebaseProvider>
        <TopProgressBar />
      </ThemeProvider>
    </>
  );
};

export default LawfullyApp;

import Fetch from "../../lib/fetch";

export default class FilingService extends Fetch {
  /**
   * Activates the user to be able to use the filing feature
   */
  public async activateUser(): Promise<void> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/users`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }

  public async verifyEmailWithToken(token: string) {
    try {
      const res = await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/users/verify-email/${token}`,
        {},
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  /**
   * Creates a new I-765 applications.
   */
  async createApplication(): Promise<{ id: string }> {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }

  async deleteApplication(id: string) {
    try {
      return await this.delete(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${id}`,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves all I-765 applications.
   */
  async getListApplication(): Promise<ResFilingApplication[]> {
    try {
      const { forms } = await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765`,
      );

      return forms ?? [];
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves a single I-765 application detail.
   */
  async getApplication(id: string): Promise<ResFilingApplicationDetail> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${id}`,
        { skipCamelCase: true },
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Posts answer to given question.
   */
  async postAnswer(
    applicationId: string,
    questionKey: string,
    answerValue: any,
  ) {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${applicationId}/${questionKey}`,
        answerValue,
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Deletes answer to given question.
   */
  async deleteAnswer(applicationId: string, questionKey: string) {
    try {
      return await this.delete(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${applicationId}/${questionKey}`,
      );
    } catch (e) {
      throw e;
    }
  }

  async uploadFile(applicationId: string, questionKey: string, file: File) {
    const fd = new FormData();
    fd.append("fileType", questionKey);
    fd.append("file", file);

    try {
      return await this.upload(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${applicationId}/upload`,
        fd,
      );
    } catch (e) {
      throw e;
    }
  }

  async deleteFile(applicationId: string, fileId: number) {
    try {
      return await this.delete(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${applicationId}/upload`,
        { id: fileId },
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves I-765 application progress.
   */
  async getProgress(id: string): Promise<{ progress: number }> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${id}/progress`,
      );
    } catch (e) {
      throw e;
    }
  }

  async createCheckoutSession(
    id: string,
    params: { successUrl: string; cancelUrl: string },
  ): Promise<string> {
    try {
      const { url } = await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${id}/payment-checkout`,
        params,
      );
      return url;
    } catch (e) {
      throw e;
    }
  }

  async requestReview(applicationId: string) {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/review/submit`,
        {
          formId: applicationId,
          formType: "i-765",
        },
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Retrieves a single I-765 application detail.
   */
  async getSelectOptions(optionType: ReqFilingSelectOptionsKey): Promise<any> {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/option-list`,
        { optionType },
      );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Updates application name.
   */
  async updateApplicationName(
    applicationId: string,
    name: string,
  ): Promise<any> {
    try {
      return await this.put(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/filing/i-765/${applicationId}/info`,
        { name },
      );
    } catch (e) {
      throw e;
    }
  }
}

import Fetch from "../../lib/fetch";

export default class NotificationService extends Fetch {
  public async getListNotification() {
    try {
      return await this.get(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/notifications`,
      );
    } catch (e) {
      throw e;
    }
  }

  public async readListNotification() {
    try {
      return await this.post(
        `${process.env.NEXT_PUBLIC_BASE_HOST}/web/v1/notifications`,
        {},
      );
    } catch (e) {
      throw e;
    }
  }
}

import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import { loadStripe } from "@stripe/stripe-js";
import ConsultationService from "domain/consultations/consultationService";
import { useAuth } from "domain/auth/authContext";
import { AsyncStatus } from "lib/enums";

interface AgoraStreamConfig {
  isAudioMuted: boolean;
  isVideoMuted: boolean;
}

interface ConsultationStore {
  dataStatus: {
    dataConsultationDetail: AsyncStatus;
    dataConsultationChatInfo: AsyncStatus;
    dataConsultationPaymentDetail: AsyncStatus;
  };
  dataConsultationDetail: ResConsultationDetail | null;
  dataConsultationChatInfo: ResConsultationChatInfo | null;
  dataAgoraStreamConfig: AgoraStreamConfig;
  dataListConsultationUpcoming: ResConsultationRequest[];
  dataListConsultationHistory: ResConsultationRequest[];
  dataConsultationPaymentDetail: ResConsultationPaymentDetail | null;
}

type ConsultationAction =
  | {
      type: "SET_CONSULTATION_DETAIL";
      val: ResConsultationDetail;
    }
  | {
      type: "SET_STATUS_CONSULTATION_DETAIL";
      val: AsyncStatus;
    }
  | {
      type: "SET_CONSULTATION_CHAT_INFO";
      val: ResConsultationChatInfo;
    }
  | {
      type: "SET_STATUS_CONSULTATION_CHAT_INFO";
      val: AsyncStatus;
    }
  | {
      type: "SET_STREAM_CONFIG";
      val: AgoraStreamConfig;
    }
  | {
      type: "SET_LIST_CONSULTATION_UPCOMING";
      val: ResConsultationRequest[];
    }
  | {
      type: "SET_LIST_CONSULTATION_HISTORY";
      val: ResConsultationRequest[];
    }
  | {
      type: "SET_CONSULTATION_PAYMENT_DETAIL";
      val: ResConsultationPaymentDetail | null;
    };

const ConsultationContext = createContext<
  [ConsultationStore, React.Dispatch<ConsultationAction>] | null
>(null);

function ConsultationProvider(props: any) {
  const [state, dispatch] = useReducer(consultationReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <ConsultationContext.Provider value={value} {...props} />;
}

function useConsultation() {
  const context = useContext(ConsultationContext);

  const {
    state: { dataUser },
  } = useAuth();

  const service = useMemo(
    () =>
      new ConsultationService(dataUser ? { token: dataUser.token } : undefined),
    [dataUser],
  );

  if (!context) {
    throw new Error(
      `useConsultation must be used within a ConsultationProvider`,
    );
  }

  const [state, dispatch] = context;

  const doGetConsultationDetail = useCallback(
    async (consultationId: string) => {
      try {
        dispatch({
          type: "SET_STATUS_CONSULTATION_DETAIL",
          val: AsyncStatus.Requested,
        });
        const data = await service.getConsultationDetail(consultationId);
        dispatch({
          type: "SET_CONSULTATION_DETAIL",
          val: data,
        });
      } catch (e) {
        dispatch({
          type: "SET_STATUS_CONSULTATION_DETAIL",
          val: AsyncStatus.Error,
        });
        throw e;
      }
    },
    [service, dispatch],
  );

  const doGetConsultationChatInfo = useCallback(
    async (consultationId: string) => {
      try {
        dispatch({
          type: "SET_STATUS_CONSULTATION_CHAT_INFO",
          val: AsyncStatus.Requested,
        });
        const data = await service.getConsultationChatInfo(consultationId);
        dispatch({
          type: "SET_CONSULTATION_CHAT_INFO",
          val: data,
        });
      } catch (e) {
        dispatch({
          type: "SET_STATUS_CONSULTATION_CHAT_INFO",
          val: AsyncStatus.Error,
        });
        throw e;
      }
    },
    [service, dispatch],
  );

  const doSetStreamConfig = useCallback(
    (config: AgoraStreamConfig) => {
      dispatch({
        type: "SET_STREAM_CONFIG",
        val: config,
      });
    },
    [dispatch],
  );

  const doCancelConsultation = useCallback(
    async (consultationId: string) => {
      try {
        const data = await service.postCancelConsultation(consultationId);
        dispatch({
          type: "SET_CONSULTATION_DETAIL",
          val: data,
        });
      } catch (e) {
        throw e;
      }
    },
    [service, dispatch],
  );

  const doCompleteConsultation = useCallback(
    async (consultationId: string) => {
      try {
        const data = await service.postCompleteConsultation(consultationId);
        dispatch({
          type: "SET_CONSULTATION_DETAIL",
          val: data,
        });
      } catch (e) {
        throw e;
      }
    },
    [service, dispatch],
  );

  const doCreateReview = useCallback(
    async (payload: ReqReview) => {
      try {
        await service.postCreateReview(payload);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doGetListConsultationUpcoming = useCallback(async () => {
    try {
      const data = await service.getListConsultationUpcoming();
      dispatch({
        type: "SET_LIST_CONSULTATION_UPCOMING",
        val: data,
      });
    } catch (e) {
      throw e;
    }
  }, [service, dispatch]);

  const doGetListConsultationHistory = useCallback(async () => {
    try {
      const data = await service.getListConsultationHistory();
      dispatch({
        type: "SET_LIST_CONSULTATION_HISTORY",
        val: data,
      });
    } catch (e) {
      throw e;
    }
  }, [service, dispatch]);

  const doGetConsultationPaymentDetail = useCallback(
    async (consultationId: string) => {
      // Delete stale payment-detail first.
      dispatch({
        type: "SET_CONSULTATION_PAYMENT_DETAIL",
        val: null,
      });
      try {
        const data = await service.getConsultationPaymentDetail(consultationId);
        dispatch({
          type: "SET_CONSULTATION_PAYMENT_DETAIL",
          val: data,
        });
      } catch (e) {
        if (e.name === "PaymentIntentNotFoundException") {
          // Request from mobile app doesn't have payment intent attached.
          await service.createPaymentIntent(consultationId);
          await doGetConsultationPaymentDetail(consultationId);
        } else {
          throw e;
        }
      }
    },
    [service, dispatch],
  );

  /**
   * Charges client's card for consultation.
   *   1. Initialize Stripe.js SDK with lawyer's Stripe account ID.
   *   2. Confirm card payment via Stripe.
   *   3. Let server know payment succeeded.
   */
  const doConfirmCardPayment = useCallback(
    async (consultationId: string) => {
      if (!state.dataConsultationPaymentDetail) {
        throw new Error(`No payment details.`);
      }
      const { stripeAccountId, clientSecret } =
        state.dataConsultationPaymentDetail;
      // [1]
      const stripe = await loadStripe(
        process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string,
        { stripeAccount: stripeAccountId },
      );
      if (!stripe) {
        throw new Error(
          `There was a problem. Please reload the page and try again.`,
        );
      }
      // [2]
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
      );
      // Handle processing error.
      if (error) {
        throw new Error(error.message);
      }
      // Handle unsuccessful charge. e.g. insufficient funds
      if (paymentIntent?.status !== "succeeded") {
        throw new Error(
          paymentIntent?.last_payment_error?.message ||
            `Your card could not be charged.`,
        );
      }
      // [3]
      await service.postCompleteConsultationPayment(consultationId);
    },
    [service, state.dataConsultationPaymentDetail],
  );

  return {
    state,
    dispatch,
    doGetConsultationDetail,
    doGetConsultationChatInfo,
    doSetStreamConfig,
    doCancelConsultation,
    doCompleteConsultation,
    doCreateReview,
    doGetListConsultationUpcoming,
    doGetListConsultationHistory,
    doGetConsultationPaymentDetail,
    doConfirmCardPayment,
  };

  // helper functions go beneath return statement
}

const initialState: ConsultationStore = {
  dataStatus: {
    dataConsultationChatInfo: AsyncStatus.Initial,
    dataConsultationDetail: AsyncStatus.Initial,
    dataConsultationPaymentDetail: AsyncStatus.Initial,
  },
  dataConsultationDetail: null,
  dataAgoraStreamConfig: {
    isAudioMuted: false,
    isVideoMuted: false,
  },
  dataConsultationChatInfo: null,
  dataListConsultationUpcoming: [],
  dataListConsultationHistory: [],
  dataConsultationPaymentDetail: null,
};

function consultationReducer(
  state: ConsultationStore,
  action: ConsultationAction,
): ConsultationStore {
  switch (action.type) {
    case "SET_CONSULTATION_DETAIL": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataConsultationDetail: AsyncStatus.Success,
        },
        dataConsultationDetail: action.val,
      };
    }

    case "SET_CONSULTATION_CHAT_INFO": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataConsultationDetail: AsyncStatus.Success,
        },
        dataConsultationChatInfo: action.val,
      };
    }

    case "SET_LIST_CONSULTATION_UPCOMING": {
      return {
        ...state,
        dataListConsultationUpcoming: action.val,
      };
    }

    case "SET_LIST_CONSULTATION_HISTORY": {
      return {
        ...state,
        dataListConsultationHistory: action.val,
      };
    }

    case "SET_CONSULTATION_PAYMENT_DETAIL": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataConsultationPaymentDetail: AsyncStatus.Success,
        },
        dataConsultationPaymentDetail: action.val,
      };
    }

    case "SET_STATUS_CONSULTATION_DETAIL": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataConsultationDetail: action.val,
        },
      };
    }

    case "SET_STATUS_CONSULTATION_CHAT_INFO": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataConsultationChatInfo: action.val,
        },
      };
    }

    case "SET_STREAM_CONFIG": {
      return {
        ...state,
        dataAgoraStreamConfig: action.val,
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { ConsultationProvider, useConsultation };

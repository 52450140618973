import { useLayout } from "lib/contexts/layoutContext";
import Modal, { ModalContent, ModalTitle } from "./Modal";

const ModalShared: React.FC = () => {
  const {
    doCloseModal,
    state: { dataModal, isModalOpen },
  } = useLayout();

  return (
    <div>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={doCloseModal}
          fullScreen={dataModal.fullScreen}
        >
          {!!dataModal.title && (
            <ModalTitle
              css={`
                &&& {
                  .modal-title-typography-root {
                    ${dataModal.titleFontSize
                      ? `font-size: ${dataModal.titleFontSize};`
                      : ""}
                  }
                }
              `}
              onClose={doCloseModal}
              skippable={dataModal.skippable}
            >
              {dataModal.title}
            </ModalTitle>
          )}
          <ModalContent>{dataModal.content}</ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default ModalShared;

import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import dayjs from "dayjs";
import { CardElement } from "@stripe/react-stripe-js";
import { AsyncStatus } from "../../lib/enums";
import { useAuth } from "../auth/authContext";
import RequestService from "./requestService";
import { Stripe, StripeElements } from "@stripe/stripe-js";

interface RequestStore {
  /**
   * Asyncronous data status.
   */
  dataStatus: {
    dataListConsultationArea: AsyncStatus;
    dataPaymentMethod: AsyncStatus;
    dataListTimeslot: AsyncStatus;
  };
  /**
   * List of consultation areas.
   */
  dataListConsultationArea: ResConsultationArea[];
  /**
   * Saved payment-method (card).
   */
  dataPaymentMethod: ResPaymentMethod | null;
  dataListTimeslot: {
    [key: string]: Array<DateString>;
  };
  /**
   * Default request data
   * Use this state to pre-fill consultation request form
   */
  dataRequestDefault: Partial<ReqConsultation>;
}

type RequestAction =
  | {
      type: "SET_STATUS";
      val: {
        dataListConsultationArea?: AsyncStatus;
        dataPaymentMethod?: AsyncStatus;
        dataListTimeslot?: AsyncStatus;
      };
    }
  | {
      type: "SET_CONSULTATION_AREAS";
      val: ResConsultationArea[];
    }
  | {
      type: "SET_PAYMENT_METHOD";
      val: ResPaymentMethod | null;
    }
  | {
      type: "SET_LIST_TIMESLOT";
      val: {
        [key: string]: Array<DateString>;
      };
    }
  | {
      type: "SET_DATA_REQUEST_DEFAULT";
      val: Partial<ReqConsultation>;
    };

const RequestContext = createContext<
  [RequestStore, React.Dispatch<RequestAction>] | null
>(null);

const RequestProvider = ({ children, ...props }: any) => {
  const [state, dispatch] = useReducer(requestReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return (
    <RequestContext.Provider value={value} {...props}>
      {children}
    </RequestContext.Provider>
  );
};

const useRequest = () => {
  const context = useContext(RequestContext);

  if (!context) {
    throw new Error(`useRequest must be used within a RequestProvider`);
  }

  const [state, dispatch] = context;
  const {
    state: { dataUser },
  } = useAuth();
  const service = useMemo(
    () => new RequestService(dataUser ? { token: dataUser.token } : undefined),
    [dataUser],
  );

  const doGetRequestInfo = useCallback(
    async (lawyerId: string) => {
      const info = await service.getRequestInfo(lawyerId);
      return info;
    },
    [service],
  );

  const doGetLawyerProfile = useCallback(
    async (lawyerId: string) => {
      const info = await service.getLawyerInfo(lawyerId);
      return info;
    },
    [service],
  );

  const doGetListLawyerTimeslot = useCallback(
    async (lawyerId: string, dateArg?: string) => {
      const date = dateArg ?? dayjs().format("YYYY-MM-DD");
      const offset = -new Date().getTimezoneOffset() / 60;

      dispatch({
        type: "SET_STATUS",
        val: {
          dataListTimeslot: AsyncStatus.Requested,
        },
      });
      try {
        const { availableTimeSlotsMap } = await service.getListLawyerTimeslot(
          lawyerId,
          date,
          offset,
        );
        dispatch({
          type: "SET_LIST_TIMESLOT",
          val: availableTimeSlotsMap,
        });
      } catch (e) {
        dispatch({
          type: "SET_STATUS",
          val: {
            dataListTimeslot: AsyncStatus.Error,
          },
        });
      }
    },
    [service, dispatch],
  );

  const doGetPaymentMethod = useCallback(async () => {
    dispatch({
      type: "SET_STATUS",
      val: {
        dataPaymentMethod: AsyncStatus.Requested,
      },
    });
    try {
      const val = await service.getPaymentMethod();
      dispatch({
        type: "SET_PAYMENT_METHOD",
        val,
      });
    } catch (err) {
      dispatch({
        type: "SET_PAYMENT_METHOD",
        val: null,
      });
    }
  }, [service, dispatch]);

  const doGetListConsultationArea = useCallback(async () => {
    const val = await service.getListConsultationArea();
    dispatch({
      type: "SET_CONSULTATION_AREAS",
      val,
    });
  }, [service, dispatch]);

  /**
   * Registers client as Stripe customer and saves card in customer obejct for later payments.
   *   1. Ask server to create setup-intent. (-> `clientSecret`)
   *   2. Confirm card setup. (-> `SetupIntent.payment_method`)
   *   3. Ask server to save payment-method ID.
   */
  const doSaveCard = useCallback(
    async ({
      name,
      country,
      stripe,
      elements,
    }: FormDataAddCard & { stripe: Stripe; elements: StripeElements }) => {
      const card = elements.getElement(CardElement);
      if (!card) {
        throw new Error("No card element found. This should not happen.");
      }
      // [1]
      const clientSecret = await service.getCardSetupSecret();
      // [2]
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card,
          billing_details: {
            name,
            address: { country },
          },
        },
      });
      // [3]
      if (result.error) {
        throw new Error(
          result.error.message || "We couldn't register your payment method.",
        );
      }
      if (!result.setupIntent?.payment_method) {
        throw new Error("We couldn't register your payment method.");
      }
      const resPaymentMethod = await service.savePaymentMethod(
        result.setupIntent.payment_method as string,
        name,
      );
      dispatch({
        type: "SET_PAYMENT_METHOD",
        val: resPaymentMethod,
      });
    },
    [dispatch, service],
  );

  /**
   * Validates a coupon code
   */
  const doValidateCoupon = useCallback(
    async (code: string) => {
      return await service.postCouponValidate({ code });
    },
    [service],
  );

  const doUpdateDefaultData = useCallback(
    (val: Partial<ReqConsultation>) => {
      dispatch({
        type: "SET_DATA_REQUEST_DEFAULT",
        val,
      });
    },
    [dispatch],
  );

  const doCreateConsultation = useCallback(
    async (params: ReqConsultation) => {
      const res = await service.createConsultation(params);
      return res;
    },
    [service],
  );

  return {
    state,
    dispatch,
    doGetRequestInfo,
    doGetLawyerProfile,
    doGetListLawyerTimeslot,
    doGetPaymentMethod,
    doGetListConsultationArea,
    doSaveCard,
    doValidateCoupon,
    doCreateConsultation,
    doUpdateDefaultData,
  };
};

const initialState: RequestStore = {
  dataStatus: {
    dataListConsultationArea: AsyncStatus.Initial,
    dataPaymentMethod: AsyncStatus.Initial,
    dataListTimeslot: AsyncStatus.Initial,
  },
  dataListConsultationArea: [],
  dataPaymentMethod: null,
  dataListTimeslot: {},
  dataRequestDefault: {
    clientName: "",
    contents: "",
    specialityTypeId: undefined,
  },
};

function requestReducer(
  state: RequestStore,
  action: RequestAction,
): RequestStore {
  switch (action.type) {
    case "SET_STATUS": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          ...action.val,
        },
      };
    }

    case "SET_CONSULTATION_AREAS": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataListConsultationArea: AsyncStatus.Success,
        },
        dataListConsultationArea: action.val,
      };
    }

    case "SET_PAYMENT_METHOD": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataPaymentMethod: AsyncStatus.Success,
        },
        dataPaymentMethod: action.val,
      };
    }

    case "SET_LIST_TIMESLOT": {
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          dataListTimeslot: AsyncStatus.Success,
        },
        dataListTimeslot: {
          // merge time slots
          ...state.dataListTimeslot,
          ...action.val,
        },
      };
    }

    case "SET_DATA_REQUEST_DEFAULT": {
      return {
        ...state,
        dataRequestDefault: {
          ...state.dataRequestDefault,
          ...action.val,
        },
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { RequestProvider, useRequest };

import React from "react";
import styled from "styled-components";
import MuiDialog, {
  DialogProps as MuiDialogProps,
} from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiIconButton from "@material-ui/core/IconButton";
import MuiTypography from "@material-ui/core/Typography";
import IconClose from "@material-ui/icons/Close";

const MODAL_PAPER_ROOT = "modal-paper-root";
const MODAL_PAPER_FULLSCREEN = "modal-paper-fullscreen";
const MODAL_PAPER_FULLWIDTH = "modal-paper-fullwidth";
const MODAL_TITLE_ROOT = "modal-title-root";
const MODAL_TITLE_TYPOGRAPHY_ROOT = "modal-title-typography-root";
const MODAL_TITLE_BUTTON_ROOT = "modal-title-button-root";

const ModalWrapper = styled((props: MuiDialogProps) => (
  <MuiDialog
    PaperProps={{
      classes: {
        root: MODAL_PAPER_ROOT,
      },
    }}
    // pass down or override props
    {...props}
  />
))`
  &&& {
    .${MODAL_PAPER_ROOT}.${MODAL_PAPER_FULLSCREEN} {
      width: 100%;
    }

    .${MODAL_PAPER_ROOT} {
      width: 550px;
    }

    .${MODAL_PAPER_ROOT}.${MODAL_PAPER_FULLWIDTH} {
      width: auto;
      max-width: 100%;
    }

    .${MODAL_TITLE_ROOT} {
      border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
      padding: 20px 30px;
      line-height: 30px;
      font-size: 20px;
    }
  }
`;

export const ModalTitle = styled(
  ({ children, onClose, skippable = true, ...props }) => (
    <MuiDialogTitle
      disableTypography
      classes={{
        root: MODAL_TITLE_ROOT,
      }}
      // pass down or override props
      {...props}
    >
      <MuiTypography
        variant="h6"
        classes={{ root: MODAL_TITLE_TYPOGRAPHY_ROOT }}
      >
        {children}
      </MuiTypography>
      {onClose && skippable && (
        <MuiIconButton
          aria-label="close"
          onClick={onClose}
          classes={{ root: MODAL_TITLE_BUTTON_ROOT }}
        >
          <IconClose />
        </MuiIconButton>
      )}
    </MuiDialogTitle>
  ),
)`
  &&& {
    margin: 0;

    .${MODAL_TITLE_TYPOGRAPHY_ROOT} {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .${MODAL_TITLE_BUTTON_ROOT} {
      position: absolute;
      right: 18px;
      top: 12px;
    }
  }
`;

export const ModalContent = styled((props) => (
  <MuiDialogContent
    // pass down or override props
    {...props}
  />
))`
  &&& {
    padding: 20px 30px 15px;
  }
`;

export const ModalActions = styled((props) => (
  <MuiDialogActions
    // pass down or override props
    {...props}
  />
))`
  &&& {
    padding: 15px 30px;
  }
`;

interface ModalProps extends MuiDialogProps {
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const { onClose, children } = props;

  return (
    <ModalWrapper
      classes={{
        paperFullScreen: MODAL_PAPER_FULLSCREEN,
        paperFullWidth: MODAL_PAPER_FULLWIDTH,
      }}
      {...props}
      onClose={(event, reason) => {
        if (
          reason === "backdropClick" &&
          (props.disableBackdropClick ?? true)
        ) {
          // by default prevent from accidental closure when entering data
          return;
        } else if (
          reason === "escapeKeyDown" &&
          (props.disableEscapeKeyDown ?? true)
        ) {
          // by default prevent from accidental closure
          // especially when some form components use ESC for canceling input (User can potentially press it multiple times)
          return;
        }
        onClose();
      }}
    >
      {children}
    </ModalWrapper>
  );
};

export default Modal;

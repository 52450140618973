import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import NotificationService from "domain/notification/notificationService";
import { useAuth } from "domain/auth/authContext";
import { AsyncStatus } from "lib/enums";

interface NotificationStore {
  status: {
    dataListNotification: AsyncStatus;
  };
  dataListNotification: ResListNotification | null;
}

type NotificationAction =
  | {
      type: "SET_STATUS_LIST_NOTIFICATION";
      val: AsyncStatus;
    }
  | {
      type: "SET_LIST_NOTIFICATION";
      val: ResListNotification;
    };

const NotificationContext = createContext<
  [NotificationStore, React.Dispatch<NotificationAction>] | null
>(null);

function NotificationProvider(props: any) {
  const [state, dispatch] = useReducer(notificationReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <NotificationContext.Provider value={value} {...props} />;
}

function useNotification() {
  const context = useContext(NotificationContext);
  const {
    state: { dataUser },
  } = useAuth();

  const service = useMemo(
    () =>
      new NotificationService(dataUser ? { token: dataUser.token } : undefined),
    [dataUser],
  );

  if (!context) {
    throw new Error(
      `useNotification must be used within a NotificationProvider.`,
    );
  }

  const [state, dispatch] = context;

  const unreadCount = state.dataListNotification?.unreadCount;

  /**
   * Retrieves notifications.
   */
  const doGetListNotification = useCallback(async () => {
    dispatch({
      type: "SET_STATUS_LIST_NOTIFICATION",
      val: AsyncStatus.Requested,
    });
    try {
      const val = await service.getListNotification();
      dispatch({
        type: "SET_LIST_NOTIFICATION",
        val,
      });
      dispatch({
        type: "SET_STATUS_LIST_NOTIFICATION",
        val: AsyncStatus.Success,
      });
    } catch (e) {
      dispatch({
        type: "SET_STATUS_LIST_NOTIFICATION",
        val: AsyncStatus.Error,
      });
      throw e;
    }
  }, [dispatch, service]);

  /**
   * Marks all notifications as read.
   */
  const doReadListNotification = useCallback(async () => {
    try {
      if (unreadCount > 0) {
        await service.readListNotification();
      }
    } catch (e) {
      // noop
    }
  }, [service, unreadCount]);

  return {
    state,
    dispatch,
    doGetListNotification,
    doReadListNotification,
  };

  // helper functions go beneath return statement
}

const initialState: NotificationStore = {
  status: {
    dataListNotification: AsyncStatus.Initial,
  },
  dataListNotification: null,
};

function notificationReducer(
  state: NotificationStore,
  action: NotificationAction,
): NotificationStore {
  switch (action.type) {
    case "SET_LIST_NOTIFICATION": {
      return {
        ...state,
        dataListNotification: action.val,
      };
    }

    case "SET_STATUS_LIST_NOTIFICATION": {
      return {
        ...state,
        status: {
          ...state.status,
          dataListNotification: action.val,
        },
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { NotificationProvider, useNotification };

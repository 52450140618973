import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import CommunityService from "domain/community/communityService";
import { useAuth } from "domain/auth/authContext";
import { AsyncStatus } from "lib/enums";

interface CommunityStore {
  status: {
    dataListCommunityCategory: AsyncStatus;
  };
  dataListCommunityCategory: ResCategoryCommunity[];
  dataListPost: {
    [index: string]: ResPagedData<ResCommunityPostItem>;
  };
  dataQueryStatePost: {
    [index: string]: ReqListPostCommunity;
  };
  dataListPostByTag: {
    [index: string]: ResPagedData<ResCommunityPostItem>;
  };
  dataQueryStatePostByTag: {
    [index: string]: ReqListPostCommunity;
  };
  dataListPostByKeyword: {
    [index: string]: ResPagedData<ResCommunityPostItem>;
  };
  dataQueryStatePostByKeyword: {
    [index: string]: ReqListPostCommunity;
  };
  dataListTagPopularByCategory: {
    [index: string]: ResTagCommunity[];
  };
  hasPostedInSession: boolean;
}

type CommunityAction =
  | {
      type: "SET_LIST_POST_BY_CATEGORY";
      val: {
        data: ResPagedData<ResCommunityPostItem>;
        queryState: ReqListPostCommunity;
        categoryId: string;
      };
    }
  | {
      type: "SET_LIST_POST_BY_TAG";
      val: {
        data: ResPagedData<ResCommunityPostItem>;
        queryState: ReqListPostCommunity;
        categoryId: string;
      };
    }
  | {
      type: "SET_LIST_POST_BY_KEYWORD";
      val: {
        data: ResPagedData<ResCommunityPostItem>;
        queryState: ReqListPostCommunity;
        categoryId: string;
      };
    }
  | {
      type: "SET_LIST_TAG_POPULAR_BY_CATEGORY";
      val: {
        data: ResTagCommunity[];
        categoryId: string;
      };
    }
  | {
      type: "SET_LIST_COMMUNITY_CATEGORY";
      val: ResCategoryCommunity[];
    }
  | {
      type: "SET_STATUS_LIST_COMMUNITY_CATEGORY";
      val: AsyncStatus;
    }
  | {
      type: "WIPE_LIST_POST_BY_TAG";
    }
  | {
      type: "WIPE_LIST_POST_BY_CATEGORY";
    }
  | {
      type: "WIPE_LIST_POST_BY_KEYWORD";
    }
  | {
      type: "SET_HAS_POSTED_IN_SESSION";
    };

const CommunityContext = createContext<
  [CommunityStore, React.Dispatch<CommunityAction>] | null
>(null);

function CommunityProvider(props: any) {
  const [state, dispatch] = useReducer(communityReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);
  const { children, ...rest } = props;

  return (
    <CommunityContext.Provider value={value} {...rest}>
      <InitData>{children}</InitData>
    </CommunityContext.Provider>
  );
}

/**
 * Initialize required data:
 *   - Community categories
 */
const InitData = React.memo(({ children }) => {
  const {
    state: { dataListCommunityCategory },
    doGetListCommunityCategory,
  } = useCommunity();

  useEffect(() => {
    if (dataListCommunityCategory.length === 0) {
      doGetListCommunityCategory();
    }
  }, [dataListCommunityCategory.length, doGetListCommunityCategory]);

  return <>{children}</>;
});

function useCommunity() {
  const context = useContext(CommunityContext);

  const {
    state: { dataUser },
  } = useAuth();

  const service = useMemo(
    () =>
      new CommunityService(dataUser ? { token: dataUser.token } : undefined),
    [dataUser],
  );

  if (!context) {
    throw new Error(`useCommunity must be used within a CommunityProvider`);
  }

  const [state, dispatch] = context;

  const doUpdateListPostsByCategory = useCallback(
    (
      categoryId: string,
      data: ResPagedData<ResCommunityPostItem>,
      queryState: ReqListPostCommunity,
    ) => {
      dispatch({
        type: "SET_LIST_POST_BY_CATEGORY",
        val: {
          categoryId,
          data,
          queryState,
        },
      });
    },
    [dispatch],
  );

  const doUpdateListPostsByTag = useCallback(
    (
      categoryId: string,
      data: ResPagedData<ResCommunityPostItem>,
      queryState: ReqListPostCommunity,
    ) => {
      dispatch({
        type: "SET_LIST_POST_BY_TAG",
        val: {
          categoryId,
          data,
          queryState,
        },
      });
    },
    [dispatch],
  );

  const doUpdateListPostsByKeyword = useCallback(
    (
      categoryId: string,
      data: ResPagedData<ResCommunityPostItem>,
      queryState: ReqListPostCommunity,
    ) => {
      dispatch({
        type: "SET_LIST_POST_BY_KEYWORD",
        val: {
          categoryId,
          data,
          queryState,
        },
      });
    },
    [dispatch],
  );

  const doUpdateListTagPopularByCategory = useCallback(
    (categoryId: string, data: ResTagCommunity[]) => {
      dispatch({
        type: "SET_LIST_TAG_POPULAR_BY_CATEGORY",
        val: {
          categoryId,
          data,
        },
      });
    },
    [dispatch],
  );

  const doGetListCommunityCategory = useCallback(async () => {
    try {
      dispatch({
        type: "SET_STATUS_LIST_COMMUNITY_CATEGORY",
        val: AsyncStatus.Requested,
      });
      const data = await service.getListCategory();
      dispatch({
        type: "SET_LIST_COMMUNITY_CATEGORY",
        val: data,
      });
    } catch (e) {
      dispatch({
        type: "SET_STATUS_LIST_COMMUNITY_CATEGORY",
        val: AsyncStatus.Error,
      });
      throw e;
    }
  }, [service, dispatch]);

  const doGetPost = useCallback(
    async (slug: string) => {
      try {
        return await service.getPost(slug);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doCreatePost = useCallback(
    async ({ categoryId, title, contents, isWithTimeline }) => {
      try {
        return await service.createPost({
          categoryId,
          title,
          contents,
          isWithTimeline,
        });
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doEditPost = useCallback(
    async (data: {
      categoryId: number;
      postId: number;
      title: string;
      contents: string;
    }) => {
      try {
        return await service.editPost(data);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doUpdatePostLike = useCallback(
    async (postId: number, on: boolean): Promise<ResCommunityPostPost> => {
      try {
        return await service.updatePostLike(postId, on);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doDeletePost = useCallback(
    async (postId: number) => {
      return await service.deletePost(postId);
    },
    [service],
  );

  const doReportPost = useCallback(
    async (data: { postId: number; title: string; contents: string }) => {
      return await service.reportPost(data);
    },
    [service],
  );

  const doReportComment = useCallback(
    async (data: { commentId: number; title: string; contents: string }) => {
      await service.reportComment(data);
    },
    [service],
  );

  const doGetListComment = useCallback(
    async ({
      postSlug,
      pageNumber,
      pageSize,
    }: {
      postSlug: string;
      pageNumber: number;
      pageSize: number;
    }) => {
      if (dataUser) {
        return await service.getListComment({
          postSlug,
          pageNumber,
          pageSize,
        });
      }
      return await service.getListCommentPublic({
        postSlug,
        pageNumber,
        pageSize,
      });
    },
    [service, dataUser],
  );

  const doDeleteComment = useCallback(
    async (commentId: number) => {
      return await service.deleteComment(commentId);
    },
    [service],
  );

  const doCreateComment = useCallback(
    async (postId: number, comment: string) => {
      try {
        return await service.createComment(postId, comment);
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doEditComment = useCallback(
    async ({ commentId, comment }: { commentId: number; comment: string }) => {
      try {
        return await service.editComment({ commentId, comment });
      } catch (e) {
        throw e;
      }
    },
    [service],
  );

  const doGetMyActivityPosts = useCallback(
    async (page: number) => {
      return await service.getMyActivityPosts(page);
    },
    [service],
  );

  const doGetMyActivityLikes = useCallback(
    async (page: number) => {
      return await service.getMyActivityLikes(page);
    },
    [service],
  );

  const doWipeDataListPostByTag = useCallback(() => {
    dispatch({
      type: "WIPE_LIST_POST_BY_TAG",
    });
  }, [dispatch]);

  const doWipeDataListPostByCategory = useCallback(() => {
    dispatch({
      type: "WIPE_LIST_POST_BY_CATEGORY",
    });
  }, [dispatch]);

  const doWipeDataListPostByKeyword = useCallback(() => {
    dispatch({
      type: "WIPE_LIST_POST_BY_KEYWORD",
    });
  }, [dispatch]);

  const doSetHasPostedInSession = useCallback(() => {
    dispatch({
      type: "SET_HAS_POSTED_IN_SESSION",
    });
  }, [dispatch]);

  return {
    state,
    dispatch,
    doUpdateListPostsByCategory,
    doUpdateListPostsByTag,
    doUpdateListPostsByKeyword,
    doUpdateListTagPopularByCategory,
    doGetListCommunityCategory,
    doGetPost,
    doCreatePost,
    doEditPost,
    doUpdatePostLike,
    doDeletePost,
    doReportPost,
    doReportComment,
    doGetListComment,
    doDeleteComment,
    doCreateComment,
    doEditComment,
    doGetMyActivityPosts,
    doGetMyActivityLikes,
    doWipeDataListPostByTag,
    doWipeDataListPostByCategory,
    doWipeDataListPostByKeyword,
    doSetHasPostedInSession,
  };

  // helper functions go beneath return statement
}

const initialState: CommunityStore = {
  dataListPost: {},
  dataQueryStatePost: {},
  dataListPostByTag: {},
  dataQueryStatePostByTag: {},
  dataListPostByKeyword: {},
  dataQueryStatePostByKeyword: {},
  dataListTagPopularByCategory: {},
  status: {
    dataListCommunityCategory: AsyncStatus.Initial,
  },
  dataListCommunityCategory: [],
  hasPostedInSession: false,
};

function communityReducer(
  state: CommunityStore,
  action: CommunityAction,
): CommunityStore {
  switch (action.type) {
    case "SET_LIST_POST_BY_CATEGORY": {
      return {
        ...state,
        dataListPost: {
          ...state.dataListPost,
          [action.val.categoryId]: action.val.data,
        },
        dataQueryStatePost: {
          ...state.dataQueryStatePost,
          [action.val.categoryId]: action.val.queryState,
        },
      };
    }

    case "SET_LIST_POST_BY_TAG": {
      return {
        ...state,
        dataListPostByTag: {
          ...state.dataListPostByTag,
          [action.val.categoryId]: action.val.data,
        },
        dataQueryStatePostByTag: {
          ...state.dataQueryStatePostByTag,
          [action.val.categoryId]: action.val.queryState,
        },
      };
    }

    case "SET_LIST_POST_BY_KEYWORD": {
      return {
        ...state,
        dataListPostByKeyword: {
          ...state.dataListPostByKeyword,
          [action.val.categoryId]: action.val.data,
        },
        dataQueryStatePostByKeyword: {
          ...state.dataQueryStatePostByKeyword,
          [action.val.categoryId]: action.val.queryState,
        },
      };
    }

    case "SET_LIST_TAG_POPULAR_BY_CATEGORY": {
      return {
        ...state,
        dataListTagPopularByCategory: {
          ...state.dataListTagPopularByCategory,
          [action.val.categoryId]: action.val.data,
        },
      };
    }

    case "SET_LIST_COMMUNITY_CATEGORY": {
      return {
        ...state,
        dataListCommunityCategory: action.val,
      };
    }

    case "SET_STATUS_LIST_COMMUNITY_CATEGORY": {
      return {
        ...state,
        status: {
          ...state.status,
          dataListCommunityCategory: action.val,
        },
      };
    }

    case "WIPE_LIST_POST_BY_TAG": {
      return {
        ...state,
        dataListPostByTag: {},
        dataQueryStatePostByTag: {},
      };
    }

    case "WIPE_LIST_POST_BY_CATEGORY": {
      return {
        ...state,
        dataListPost: {},
      };
    }

    case "WIPE_LIST_POST_BY_KEYWORD": {
      return {
        ...state,
        dataListPostByKeyword: {},
        dataQueryStatePostByKeyword: {},
      };
    }

    case "SET_HAS_POSTED_IN_SESSION": {
      return {
        ...state,
        hasPostedInSession: true,
      };
    }

    default: {
      throw new Error(`Unsupported action type`);
    }
  }
}

export { CommunityProvider, useCommunity };
